import React, { useEffect, useState } from "react";
import TopNav from "../components/common/topnav.component";
import { Carousel } from 'primereact/carousel';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';
import { useDispatch, useSelector } from "react-redux";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {
    fetchAt_A_Glance_Top_Tile
} from '../redux/slices/counter';
import { Tooltip } from "antd";

/******Main Fact ******/
import Assessment_icon from '../assets/images/assessment_icon.png'
import Attendance_icon from '../assets/images/attendance_icon.png'
import Graduation_icon from '../assets/images/graduation_icon.png'
import District_Ranking_icon from '../assets/images/District_Ranking_icon.png'
import Student_Behavior_icon from '../assets/images/Student_Behavior_icon.png'
import Complogo from '../assets/images/GoalsStrategyLogo.svg';

import Enrollment from "./dashboardtiles/enrollment.component";
import StudentCharacteristics from "./dashboardtiles/studentcharacteristics.component";
import SchoolDistrictEmployees from "./dashboardtiles/schooldistrictemployees.component";
import SchoolsCenters from "./dashboardtiles/schoolscenters.component";
import FinancialInformation from "./dashboardtiles/financialiInformation.component";
import LoaderContainer from "../components/loaderContainer";


export default function Dashboard(props) {
    const dispatch = useDispatch();
    const At_A_Glance_Top_Tiledata = useSelector(state => state.netsales.At_A_Glance_Top_Tile);
    const At_A_Glance_Top_Tiledataloading = useSelector(state => state.netsales.At_A_Glance_Top_Tileloading);
    // console.log("At_A_Glance_Top_Tiledata",At_A_Glance_Top_Tiledata)

    useEffect(() => {
        dispatch(fetchAt_A_Glance_Top_Tile({
            "elasticQueryName": "At_A_Glance_Top_Tile",
            "filters": [{
                "columnName": "SCHOOL_YEAR",
                "columnValue": ["2025"],
                "excludeKeyword": false
            }],
            "dynamicColumns": [],
        }));
    }, [])
    // const [mainFacts, setMainFacts] = useState(dataset);
    const [mainDashboard, setMainDashboard] = useState([]);

    const timeAnalysis = (data, key) => {
        if (data && data.length) {
            let newMetric = [];
            let dataset = [];
            let new_obj = {};
            data.forEach(obj => {
                if (newMetric.indexOf(obj[key]) == -1) {
                    new_obj = obj;
                    var index = 0;
                    data.forEach(row => {
                        if (obj[key] == row[key]) {
                            let order = index + 1;
                            new_obj["category_" + order] = row['METRIC_TYPE'] == "number" ? { value: (row['GROUPBY_METRIC_NUMERATOR'])?.toLocaleString(), suffix: "" } : { value: row["PERCENTAGE"]?.toFixed(1) == "0.0" ? "0" : row["PERCENTAGE"]?.toFixed(1), suffix: row['METRIC_SUFFIX'] };
                            new_obj["category_label_" + order] = row["METRIC_NAME"] == '% of student above NWEA ELA benchmark' ? '% of student above ELA benchmark' : row["METRIC_NAME"];
                            index++;
                        }
                    });
                    dataset.push(new_obj);
                    newMetric.push(obj[key]);
                }
            });
            return dataset;
        }
    };
    //dummy data for the at a glance page
    let yeardata = JSON.parse(sessionStorage.getItem("Year")) == undefined || null ? "2024" : JSON.parse(sessionStorage.getItem("Year"))

    // let yeardata =JSON.parse(sessionStorage.getItem("Year")) || "2023" 
    // yeardata = (yeardata == "2024")?"2023":yeardata;

    let annualGraduationCCR = {
        "2018": 147.14,
        "2019": 150.46,
        // {"2020" : '-'},
        // {"2021" : '-'},
        "2022": 161.58,
        "2023": 155.58,
        "2024": 161.48
    }
    // console.log("data",annualGraduationCCR[`${yeardata}`] || "-",yeardata,annualGraduationCCR[`${yeardata}`])
    const dummyData = [
        {
            "METRIC_ID": 0,
            "SCHOOL_YEAR": 0,
            "METRIC_NAME": "Annual CCCR Index",
            "GROUPBY_METRIC_NUMERATOR": 0,
            "GROUPBY_METRIC_DENOMINATOR": 0,
            "PERCENTAGE": annualGraduationCCR[`${yeardata}`] || 0,
            "METRIC_DESCRIPTION": "",
            "METRIC_GROUP": "Graduation",
            "METRIC_GROUP_ORDER": 0,
            "METRIC_GROUP_DESCRIPTION": "",
            "METRIC_GROUP_ICON_LOCATION": "",
            "METRIC_DISPLAY_ORDER": 0,
            "METRIC_ICON_LOCATION": "",
            "METRIC_MET_LABEL": "",
            "METRIC_NOT_MET_LABEL": null,
            "METRIC_TYPE": "percentage",
            "METRIC_PREFIX": null,
            "METRIC_MASK": "",
            "METRIC_PRECISION": 0,
            "METRIC_INDICATOR_TYPE": "positive"
        },
        //   {
        //     "METRIC_ID": 0,
        //     "SCHOOL_YEAR": 0,
        //     "METRIC_NAME": "Retention Rate",
        //     "GROUPBY_METRIC_NUMERATOR": "",
        //     "GROUPBY_METRIC_DENOMINATOR": 0,
        //     "PERCENTAGE": 0,
        //     "METRIC_DESCRIPTION": "",
        //     "METRIC_GROUP": "Staff",
        //     "METRIC_GROUP_ORDER": 0,
        //     "METRIC_GROUP_DESCRIPTION": "",
        //     "METRIC_GROUP_ICON_LOCATION": "",
        //     "METRIC_DISPLAY_ORDER": 0,
        //     "METRIC_ICON_LOCATION": "",
        //     "METRIC_MET_LABEL": "",
        //     "METRIC_NOT_MET_LABEL": null,
        //     "METRIC_TYPE": "number",
        //     "METRIC_SUFFIX": "",
        //     "METRIC_PREFIX": null,
        //     "METRIC_MASK": "",
        //     "METRIC_PRECISION": 0,
        //     "METRIC_INDICATOR_TYPE": "positive"
        //   },
    ]
    // datasetnew = datasetnew?.filter(x => x?.METRIC_GROUP !== "Enrollment")
    // ?.concat(dummydata)
    // ========end of dummy data===========
    var alertnew = [...JSON.parse(JSON.stringify(At_A_Glance_Top_Tiledata)), ...dummyData]; //remove dummy
    let aaa = alertnew?.filter(itr => itr["METRIC_NAME"] != "% of SPED - NWEA")
    let bbb = alertnew?.filter(itr => itr["METRIC_NAME"] == "% of SPED - NWEA")

    /* Handle Student Behaviour Tile position changes */
    aaa = aaa?.sort((a, b) => {
        if (a["METRIC_GROUP"] == "Student Behavior" || b["METRIC_GROUP"] == "Student Behavior") {
            return b?.METRIC_NAME.localeCompare(a?.METRIC_NAME)
        }
    })
    /*  */

    let dataset = timeAnalysis(aaa, "METRIC_GROUP");
    let dataset1 = timeAnalysis(bbb, "METRIC_NAME");
    let datasetEnrollFilter = dataset?.filter(item => item['METRIC_GROUP'] !== 'Enrollment');
    console.log(datasetEnrollFilter, 'datasetEnrollFilter')
    datasetEnrollFilter?.map(itr => {
        if (itr["METRIC_GROUP"] == "Attendance") {
            itr["img"] = "Attendance_icon"
        }
        if (itr["METRIC_GROUP"] == "Student Behavior") {
            itr["img"] = "Student_Behavior_icon"
        }
        if (itr["METRIC_GROUP"] == "ELA Assessment") {
            itr["img"] = "Assessment_icon"
        }
        if (itr["METRIC_GROUP"] == "Assessment") {
            itr["img"] = "Assessment_icon"
        }
        if (itr["METRIC_GROUP"] == "Math Assessment") {
            itr["img"] = "Assessment_icon"
        }
    })


    let datasetstatic = [
        // {
        // "METRIC_ID": 10001,
        // "SCHOOL_YEAR": 2022,
        // "METRIC_NAME": "Percent of students with > 95 Percent attendance",
        // "GROUPBY_METRIC_NUMERATOR": 317981,
        // "GROUPBY_METRIC_DENOMINATOR": 753485,
        // "PERCENTAGE": 42.2014,
        // "METRIC_DESCRIPTION": "This indicator gives the percentage of students with excellent attendance",
        // "METRIC_GROUP": "Graduation",
        // "METRIC_GROUP_ORDER": 1,
        // "METRIC_GROUP_DESCRIPTION": "School Level statistics covering student engagement details",
        // "METRIC_GROUP_ICON_LOCATION": "/metric_group_icon_location/Attendance.jpg",
        // "METRIC_DISPLAY_ORDER": 1,
        // "METRIC_ICON_LOCATION": "/metric_icon_location/% of students with >95% attendance.jpg",
        // "METRIC_MET_LABEL": "Attendance above 95%",
        // "METRIC_NOT_MET_LABEL": "Attendance less than 95%",
        // "METRIC_TYPE": "percentage",
        // "METRIC_SUFFIX":"-",
        // "METRIC_PREFIX": null,
        // "METRIC_MASK": "##.0",
        // "METRIC_PRECISION": 1,
        // "METRIC_INDICATOR_TYPE": "positive",
        // "category_1": "-",
        // "category_label_1": "% of Eco.Dis Graduates - CCMR Bonus",
        // "category_2": "-",
        // "category_label_2": "Graduation Rate",
        // "img": Graduation_icon
        // },
        // {
        // "METRIC_ID": 10080,
        // "SCHOOL_YEAR": 2022,
        // "METRIC_NAME": "Suspension Rate",
        // "GROUPBY_METRIC_NUMERATOR": 1425,
        // "GROUPBY_METRIC_DENOMINATOR": 9690,
        // "PERCENTAGE": 14.7059,
        // "METRIC_DESCRIPTION": "This indicator gives the percentage of students with suspension",
        // "METRIC_GROUP": "District Ranking Details",
        // "METRIC_GROUP_ORDER": 3,
        // "METRIC_GROUP_DESCRIPTION": "District and school level statistics on Student behavior metrics like Referrals, Interventions, Suspensions etc.",
        // "METRIC_GROUP_ICON_LOCATION": "/metric_group_icon_location/Student Behavior.jpg",
        // "METRIC_DISPLAY_ORDER": 1,
        // "METRIC_ICON_LOCATION": "/metric_icon_location/% of students with suspension.jpg",
        // "METRIC_MET_LABEL": "% of students with suspension",
        // "METRIC_NOT_MET_LABEL": null,
        // "METRIC_TYPE": "percentage",
        // "METRIC_SUFFIX": "-",
        // "METRIC_PREFIX": null,
        // "METRIC_MASK": "##.0",
        // "METRIC_PRECISION": 1,
        // "METRIC_INDICATOR_TYPE": "negative",
        // "category_1": "-",
        // "category_label_1": "--",
        // "category_2": "-",
        // "category_label_2": "--",
        // "img": District_Ranking_icon
        // },
        {
            "METRIC_ID": 10080,
            "SCHOOL_YEAR": 2022,
            "METRIC_NAME": "Suspension Rate",
            "GROUPBY_METRIC_NUMERATOR": 1425,
            "GROUPBY_METRIC_DENOMINATOR": 9690,
            "PERCENTAGE": 14.7059,
            "METRIC_DESCRIPTION": "This indicator gives the percentage of students with suspension",
            "METRIC_GROUP": "Assessment",
            "METRIC_GROUP_ORDER": 3,
            "METRIC_GROUP_DESCRIPTION": "District and school level statistics on Student behavior metrics like Referrals, Interventions, Suspensions etc.",
            "METRIC_GROUP_ICON_LOCATION": "/metric_group_icon_location/Student Behavior.jpg",
            "METRIC_DISPLAY_ORDER": 1,
            "METRIC_ICON_LOCATION": "/metric_icon_location/% of students with suspension.jpg",
            "METRIC_MET_LABEL": "% of students with suspension",
            "METRIC_NOT_MET_LABEL": null,
            "METRIC_TYPE": "percentage",
            "METRIC_SUFFIX": "-",
            "METRIC_PREFIX": null,
            "METRIC_MASK": "##.0",
            "METRIC_PRECISION": 1,
            "METRIC_INDICATOR_TYPE": "negative",
            "category_1": "-",
            "category_label_1": "% of student above NWEA ELA benchmark",
            "category_2": "-",
            "category_label_2": "% of student above NWEA Math benchmark",
            "img": Assessment_icon
        },

        {
            "METRIC_ID": 10080,
            "SCHOOL_YEAR": 2022,
            "METRIC_NAME": "Suspension Rate",
            "GROUPBY_METRIC_NUMERATOR": 1425,
            "GROUPBY_METRIC_DENOMINATOR": 9690,
            "PERCENTAGE": 14.7059,
            "METRIC_DESCRIPTION": "This indicator gives the percentage of students with suspension",
            "METRIC_GROUP": "ELA Assessment",
            "METRIC_GROUP_ORDER": 3,
            "METRIC_GROUP_DESCRIPTION": "District and school level statistics on Student behavior metrics like Referrals, Interventions, Suspensions etc.",
            "METRIC_GROUP_ICON_LOCATION": "/metric_group_icon_location/Student Behavior.jpg",
            "METRIC_DISPLAY_ORDER": 1,
            "METRIC_ICON_LOCATION": "/metric_icon_location/% of students with suspension.jpg",
            "METRIC_MET_LABEL": "% of students with suspension",
            "METRIC_NOT_MET_LABEL": null,
            "METRIC_TYPE": "percentage",
            "METRIC_SUFFIX": "-",
            "METRIC_PREFIX": null,
            "METRIC_MASK": "##.0",
            "METRIC_PRECISION": 1,
            "METRIC_INDICATOR_TYPE": "negative",
            "category_1": "-",
            "category_label_1": "% of SPED - STAAR",
            "category_2": "-",
            "category_label_2": "--",
            "img": Assessment_icon
        },

        {
            "METRIC_ID": 10080,
            "SCHOOL_YEAR": 2022,
            "METRIC_NAME": "Suspension Rate",
            "GROUPBY_METRIC_NUMERATOR": 1425,
            "GROUPBY_METRIC_DENOMINATOR": 9690,
            "PERCENTAGE": 14.7059,
            "METRIC_DESCRIPTION": "This indicator gives the percentage of students with suspension",
            "METRIC_GROUP": "ELA Assessment",
            "METRIC_GROUP_ORDER": 3,
            "METRIC_GROUP_DESCRIPTION": "District and school level statistics on Student behavior metrics like Referrals, Interventions, Suspensions etc.",
            "METRIC_GROUP_ICON_LOCATION": "/metric_group_icon_location/Student Behavior.jpg",
            "METRIC_DISPLAY_ORDER": 1,
            "METRIC_ICON_LOCATION": "/metric_icon_location/% of students with suspension.jpg",
            "METRIC_MET_LABEL": "% of students with suspension",
            "METRIC_NOT_MET_LABEL": null,
            "METRIC_TYPE": "percentage",
            "METRIC_SUFFIX": "-",
            "METRIC_PREFIX": null,
            "METRIC_MASK": "##.0",
            "METRIC_PRECISION": 1,
            "METRIC_INDICATOR_TYPE": "negative",
            "category_1": "-",
            "category_label_1": "% of 3rd Grade EcoDis - STAAR",
            "category_2": "-",
            "category_label_2": "% of 6-8th Grade EcoDis - STAAR",
            "img": Assessment_icon
        },

        {
            "METRIC_ID": 10080,
            "SCHOOL_YEAR": 2022,
            "METRIC_NAME": "Suspension Rate",
            "GROUPBY_METRIC_NUMERATOR": 1425,
            "GROUPBY_METRIC_DENOMINATOR": 9690,
            "PERCENTAGE": 14.7059,
            "METRIC_DESCRIPTION": "This indicator gives the percentage of students with suspension",
            "METRIC_GROUP": "Math Assessment",
            "METRIC_GROUP_ORDER": 3,
            "METRIC_GROUP_DESCRIPTION": "District and school level statistics on Student behavior metrics like Referrals, Interventions, Suspensions etc.",
            "METRIC_GROUP_ICON_LOCATION": "/metric_group_icon_location/Student Behavior.jpg",
            "METRIC_DISPLAY_ORDER": 1,
            "METRIC_ICON_LOCATION": "/metric_icon_location/% of students with suspension.jpg",
            "METRIC_MET_LABEL": "% of students with suspension",
            "METRIC_NOT_MET_LABEL": null,
            "METRIC_TYPE": "percentage",
            "METRIC_SUFFIX": "-",
            "METRIC_PREFIX": null,
            "METRIC_MASK": "##.0",
            "METRIC_PRECISION": 1,
            "METRIC_INDICATOR_TYPE": "negative",
            "category_1": "-",
            "category_label_1": "% of 3rd Grade EcoDis - STAAR in Math",
            "category_2": "-",
            "category_label_2": "% of 6-8th Grade EcoDis - STAAR in Math",
            "img": Assessment_icon
        }
    ]
    let datasetnew;
    if (dataset1?.length > 0) {
        datasetnew = datasetEnrollFilter?.concat(dataset1);
    } else {
        datasetnew = dataset;
    }

    datasetnew?.map(itr => {
        if (itr["METRIC_GROUP"] == "Attendance") {
            itr["ORDER"] = 1
        }
        if (itr["METRIC_GROUP"] == "Student Behavior") {
            itr["ORDER"] = 2
        }
        if (itr["METRIC_GROUP"] == "Assessment") {
            itr["ORDER"] = 3
        }
        if (itr["METRIC_GROUP"] == "ELA Assessment" && itr["METRIC_ID"] == 10092) {
            itr["ORDER"] = 4
        }
        if (itr["METRIC_GROUP"] == "ELA Assessment" && itr["METRIC_ID"] == 10093 || itr["METRIC_ID"] == 10096) {
            itr["ORDER"] = 5
        }
        if (itr["METRIC_GROUP"] == "Math Assessment") {
            itr["ORDER"] = 6
        }
        if (itr["METRIC_GROUP"] == "Assessment - STAAR") {
            itr["ORDER"] = 7
        }
        if (itr["METRIC_GROUP"] == "Reading Assessment - STAAR") {
            itr["ORDER"] = 8
        }
        if (itr["METRIC_GROUP"] == "STAAR Assessment") {
            itr["ORDER"] = 9
        }
        if (itr["METRIC_GROUP"] == "Math Assessment - STAAR") {
            itr["ORDER"] = 10
        }
        if (itr["METRIC_GROUP"] == "Academics") {
            itr["ORDER"] = 11
        }
        if (itr["METRIC_GROUP"] == "Graduation") {
            itr["ORDER"] = 12
        }
        if (itr["METRIC_GROUP"] == "Staff") {
            itr["ORDER"] = 13
        }
    })

    if (datasetnew) {
        datasetnew = datasetnew.slice().sort((a, b) => {
            return a.ORDER - b.ORDER
        })
    }


    // console.log("datasetnew",datasetnew)
    // const [mainFacts, setMainFacts] = useState(dataset);

    /********Main Fact **********/
    const responsiveOptions = [

        {
            breakpoint: '865px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '1000px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '1100px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '1300px',
            numVisible: 5,
            numScroll: 1
        },
        {
            breakpoint: '1500px',
            numVisible: 5,
            numScroll: 1
        },
        {
            breakpoint: '1700px',
            numVisible: 5,
            numScroll: 1
        },
        {
            breakpoint: '1900px',
            numVisible: 5,
            numScroll: 1
        },
        {
            breakpoint: '3000px',
            numVisible: 5,
            numScroll: 1
        }

    ];
    // const mainFactservice = {
    //     getmainFactsData() {
    //         return dataset;
    //     },

    //     getmainFactsSmall() {
    //         return Promise.resolve(this.getmainFactsData()?.slice(0, 10));
    //     },

    // };
    /********Main Fact end **********/
    /********main Dashboard**********/
    const mainDashboardservice = {
        getmainDashboardData() {
            return [
                {
                    "silder": <Enrollment />
                },
                {
                    "silder": <StudentCharacteristics />
                }
                ,
                {
                    "silder": <SchoolDistrictEmployees />
                }
                ,
                {
                    "silder": <SchoolsCenters />
                }
                ,
                {
                    "silder": <FinancialInformation />
                }
            ];
        },

        getmainDashboardSmall() {
            return Promise.resolve(this.getmainDashboardData().slice(0, 10));
        },

    };
    /******** mainDashboard end **********/

    useEffect(() => {
        // mainFactservice.getmainFactsSmall().then((data) => setMainFacts(dataset));
        mainDashboardservice.getmainDashboardSmall().then((data) => setMainDashboard(data.slice(0, 9)));

    }, []);

    const MainFactsTemplate = (mainFacts) => {
        // debugger
        return (
            <div className="product-item select-none">
                <div className="mnfcts-carimg product-item-content bg-[#0e1b28]  min-h-[110px]" style={{ marginRight: "7px" }}>
                    <div className="dashBoardToptile ">
                        <div className="p-1 px-2 flex gap-5">
                            <div >
                                {
                                    mainFacts?.METRIC_GROUP == "Attendance" ? <img src={Attendance_icon} alt='' className="w-[35px] h-[35px]" /> :
                                        mainFacts?.METRIC_GROUP == "Student Behavior" ? <img src={Student_Behavior_icon} alt='' className="w-[35px] h-[35px]" /> :
                                            //    <img src={mainFacts?.img} alt='' className="w-[35px] h-[35px]" />
                                            <img src={Assessment_icon} alt='' className="w-[35px] h-[35px]" />
                                }
                                {/* // <img src={mainFacts?.img} alt='' className="w-[35px] h-[35px]" /> */}
                            </div>
                            <div>
                                <p className="text-[14px] text-[#fff] font-[400]" style={{ top: "5px", position: "relative", fontSize: "14px", }}>{mainFacts?.METRIC_GROUP}</p>
                                {/* <h2 className="text-[18px] text-[#fff]">{mainFacts.title}</h2> */}
                            </div>
                        </div>

                    </div>
                    <div className=" dashBoardToptile1">
                        <div className="flex p-2 px-4 grid grid-cols-12 gap-3 mt-3] h-[80px]">
                            {/* mb-2 info hovertitle */}
                            <div className=" col-span-6 max-h-[60px] overflow-hidden dashBoardToptile2">
                                <Tooltip title={mainFacts?.category_label_1} color={"black"}>
                                    <p className="text-[12px] desc_minheight"> {mainFacts?.category_label_1}</p>
                                </Tooltip>
                                <p className="text-[16px]  font-semibold"> {mainFacts?.category_1?.value}{mainFacts?.category_1?.suffix}</p>
                            </div>

                            <div className="col-span-6 max-h-[60px] overflow-hidden dashBoardToptile2">
                                <Tooltip title={mainFacts?.category_label_2} color={"black"}>
                                    <p className="text-[12px] desc_minheight"> {mainFacts?.category_label_2}</p>
                                </Tooltip>

                                <p className="text-[16px] font-semibold"> {mainFacts?.category_2?.value}{mainFacts?.category_2?.suffix}</p>
                            </div>
                            {/* <div className="col-span-6 max-h-[70px] overflow-hidden">
                                <p className="text-[12px] text-[#fff] "> {mainFacts?.category_label_3}</p>
                                {mainFacts?.category_3 && <p className="text-[14px] text-[#fff] font-semibold"> {mainFacts?.category_3}{mainFacts?.METRIC_SUFFIX}</p>}
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const mainDashboardTemplate = (mainDashboard) => {
        // console.log(mainDashboard[0])
        return (
            <div className="product-item">
                <div className="EnrollmentTile h-full product-item-content ">
                    <div>
                        {mainDashboard.silder}
                    </div>
                </div>
            </div>
        );
    };


    return (
        <div className="pt-0 dashboard">
            <TopNav pagename="District at a Glance" pagenamedescription="Key Facts about the District" setUserData={props.setUserData} />
            <div className="mb-20 main-facts mt-[1vw] ">
                <div className="px-10 mb-20 dashboardmainfact">
                    <h6 className="text-[16px] text-[#fff] pt-2 font-[400] mx-3">Main Facts</h6>
                    <p className="text-[12px] text-[#fff] mx-3">The scorecard reflects the District's position in the key measurement areas</p>
                    <LoaderContainer loading={At_A_Glance_Top_Tiledataloading}>
                        <div className="mt-[1vw] mb-[1vw] px-3">
                            {
                                datasetnew && <Carousel responsiveOptions={responsiveOptions} value={datasetnew} numVisible={4} numScroll={4} itemTemplate={MainFactsTemplate} />
                            }
                        </div>
                    </LoaderContainer>
                </div>
            </div>
            <div className="px-10 pb-3 mt-10">
                <div className="mt-10 dashboard-silder dash-slid-img px-3">
                    {/* <Carousel value={mainDashboard} numVisible={4} numScroll={1}
                     responsiveOptions={responsiveOptions}/> */}

                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={8}
                        slidesPerView={4}
                        navigation
                        breakpoints={{
                            500: {
                                slidesPerView: 2
                            },
                            865: {
                                slidesPerView: 3
                            },
                            1000: {
                                slidesPerView: 3
                            },
                            1100: {
                                slidesPerView: 4
                            },
                            1300: {
                                slidesPerView: 5
                            },
                            1500: {
                                slidesPerView: 5
                            },
                            1700: {
                                slidesPerView: 5
                            },
                            2000: {
                                slidesPerView: 5
                            }
                        }}
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                    >
                        <SwiperSlide> <Enrollment /></SwiperSlide>
                        <SwiperSlide> <StudentCharacteristics /></SwiperSlide>
                        <SwiperSlide> <SchoolDistrictEmployees /></SwiperSlide>
                        <SwiperSlide> <SchoolsCenters /></SwiperSlide>
                        <SwiperSlide> <FinancialInformation /></SwiperSlide>
                    </Swiper>
                </div>
            </div>
            {/* <div className=" footer flex py-1"> */}
            <div class="footer flex justify-between p-2 mt-[-90px]">
                <div class="flex mt-2">
                    {/* <div class="mx-5 mt-2">Conditions of Use</div>
                    <div class="mx-5 mt-2">Privacy Notice</div>
                    <div class="mx-5 mt-2">@2024 Greece | All right reserved</div> */}
                </div>
                <div class="mr-3">
                    <img src={Complogo} height="50px" width="130px" alt="logo" className="mr-[2vw]" />
                </div>
            </div>
            {/* </div> */}
        </div>
    )
}