
import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';
import Finance_bannerImg from "../../assets/images/finance_banner.jpeg";
import TreadingarrowDown from "../../assets/images/treading-arrow-down.png"
import TreadingarrowUp from '../../assets/images/treading-arrow-up.png';
import { useDispatch, useSelector } from "react-redux";

function toMillion(value) {
  if (value < 0) {

    return Math.abs(Number(value)) >= 1.0e9
      ? "$" + -(Math.abs(Number(value)) / 1.0e6).toFixed(1) + "M"
      : // Six Zeroes for Millions
      Math.abs(Number(value)) >= 1.0e6
        ? "$" + -(Math.abs(Number(value)) / 1.0e6).toFixed(1) + "M"
        : // Three Zeroes for Thousands
        Math.abs(Number(value)) >= 1.0e3
          ? "$" + -(Math.abs(Number(value)) / 1.0e6).toFixed(1) + "M"
          : "$" + -(Math.abs(Number(value)) / 1.0e6).toFixed(1) + "M";
  } else {
    return Math.abs(Number(value)) >= 1.0e9
      ? "$" + (Math.abs(Number(value)) / 1.0e6).toFixed(1) + "M"
      : // Six Zeroes for Millions
      Math.abs(Number(value)) >= 1.0e6
        ? "$" + (Math.abs(Number(value)) / 1.0e6).toFixed(1) + "M"
        : // Three Zeroes for Thousands
        Math.abs(Number(value)) >= 1.0e3
          ? "$" + (Math.abs(Number(value)) / 1.0e6).toFixed(1) + "M"
          : "$" + (Math.abs(Number(value)) / 1.0e6).toFixed(1) + "M";
  }
}
export default function FinancialInformation() {
  const DarkMode = useSelector((state) => state.global.DarkMode);

  useEffect(() => {

  }, []);
  const [screenWidth, setScreenWidth] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined') {
        const screenWidth = window.innerWidth;
        setScreenWidth(screenWidth);
        console.log(screenWidth, "setScreenWidth");
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);




  const generalRevenues = {
    "2025": {
      "Property Taxes": 12176416,
      "Non Property Taxes": 1366545,
      "Charges for services": 303746,
      "Use of money and property": 499978,
      "State sources": 13188525,
      "Federal sources": 119989,
      "Other": 338038,
      "Total Revenue": 27923467
    },
    "2024": {
      "Property Taxes": 121766416,
      "Non Property Taxes": 13665045,
      "Charges for services": 3037746,
      "Use of money and property": 4299978,
      "State sources": 131885205,
      "Federal sources": 1199849,
      "Other": 3380398,
      "Total Revenue": 279234637
    },
    "2023": {
      "Property Taxes": 121766416,
      "Non Property Taxes": 13665045,
      "State and Federal Aid": 133085054,
      "Investment Earnings": 4555442,
      "Compensation for Loss": 335688,
      "Miscellaneous": 2616529,
      "Total Revenue": 309631808
    },
    "2022": {
      "Property Taxes": 118831334,
      "Non Property Taxes": 13620918,
      "State and Federal Aid": 110348818,
      "Investment Earnings": 208468,
      "Compensation for Loss": 2604549,
      "Miscellaneous": 11584838,
      "Total Revenue": 290555531
    },
    "2021": {
      "Property Taxes": 117027430,
      "Non Property Taxes": 12147718,
      "State and Federal Aid": 100470032,
      "Investment Earnings": 142143,
      "Compensation for Loss": 145341,
      "Miscellaneous": 3457569,
      "Total Revenue": 250210746
    },
    "2020": {
      "Property Taxes": 114506870,
      "Non Property Taxes": 10982379,
      "State and Federal Aid": 100726919,
      "Investment Earnings": 1079469,
      "Compensation for Loss": 412990,
      "Miscellaneous": 5469107,
      "Total Revenue": 250674670
    },
    "2019": {
      "Property Taxes": "0",
      "Non Property Taxes": "0",
      "State and Federal Aid": "0",
      "Investment Earnings": "0",
      "Compensation for Loss": "0",
      "Miscellaneous": "0",
      "Total Revenue": "0"
    },
  }

  const programExpense = {
    "2025": {
      "General Support": 30297983,
      "Instruction": 127113166,
      "Pupil transportation": 17590238,
      "Community Services": 653731,
      "Employee benefits": 55815876,
      "Capital outlay": 19525449,
      "Other": 32575357,
      "TOTAL EXPENSES": 283571800
    },
    "2024": {
      "General Support": 30297983,
      "Instruction": 127113166,
      "Pupil transportation": 17590238,
      "Community Services": 653731,
      "Employee benefits": 55815876,
      "Capital outlay": 19525449,
      "Other": 32575357,
      "TOTAL EXPENSES": 283571800
    },
    "2023": {
      "General Support": 40721301,
      "Instruction": 208215178,
      "Pupil transportation": 26680090,
      "Community Services": 1041331,
      "School lunch": 8172685,
      "Interest": 4648875,
      "TOTAL EXPENSES": 289479460
    },
    "2022": {
      "General Support": 32461991,
      "Instruction": 183631864,
      "Pupil transportation": 22596899,
      "Community Services": 579624,
      "School lunch": 6518203,
      "Interest": 3973426,
      "TOTAL EXPENSES": 249752414
    },
    "2021": {
      "General Support": 28525495,
      "Instruction": 190720742,
      "Pupil transportation": 19259428,
      "Community Services": 15039100,
      "School lunch": 4463392,
      "Interest": 3497910,
      "TOTAL EXPENSES": 246617358
    },
    "2020": {
      "General Support": 28169768,
      "Instruction": 192129770,
      "Pupil transportation": 20523564,
      "Community Services": "-",
      "School lunch": 6533854,
      "Interest": 2674879,
      "TOTAL EXPENSES": 250031835
    },
    "2019": {
      "General Support": "0",
      "Instruction": "0",
      "Pupil transportation": "0",
      "Community Services": "0",
      "School lunch": "0",
      "Interest": "0",
      "TOTAL EXPENSES": "0"
    },
  }



  let Year = JSON.parse(sessionStorage.getItem("Year")) || "2025"
  Year = (Year == "2025") ? "2025" : Year;
  console.log('year at financial===>', Year, generalRevenues[Year])

  const FinInfo1 = {
    // tooltip: {
    //   trigger: 'item',
    //   backgroundColor: 'rgba(50,50,50,0.7)',
    //   borderColor: "#333",
    //   textStyle: {
    //     fontSize: 12,
    //     fontWeight: "normal",
    //     color: '#fff',
    //   },
    // axisPointer: {
    //   type: 'shadow'
    // }

    // },
    grid: {
      left: '9%', // Align grid to the left
      containLabel: true
    },
    tooltip: {
      extraCssText: 'width: 50%; white-space: pre-wrap;',
      trigger: "item",
      formatter: function (params) {
        return (
          params.name + " : " + params.percent.toFixed(1) + "%" + " (" + toMillion(params.value) + ")"
        );
      },
      axisPointer: {
        type: 'shadow'
      },
      confine: true
    },

    legend: {
      itemGap: 4,
      orient: 'vertical',
      right: Year == 2025 ? (screenWidth <= 1450 && screenWidth >= 865 ? '22%' : '10%') : (screenWidth <= 1450 && screenWidth >= 865 ? '22%' : '15%'),
      top: '8%',

      // bottom: "2%",
      // left: "0%",
      itemWidth: 6,
      itemHeight: 6,
      formatter: function (name) {
        // Customize legend item text here, wrap text and show remaining text as dotted
        const maxLength = screenWidth <= 1450 && screenWidth >= 865 ? 20 : 33; // maximum characters before truncating
        if (name.length > maxLength) {
          return name.slice(0, maxLength) + '...'; // show first maxLength characters and append '...'
        } else {
          return name;
        }
      },
      textStyle: {
        color: DarkMode ? 'White' : 'black',
        fontSize: '11',
        fontStyle: 'normal',
        fontFamily: 'Roboto, sans-serif',

      }
    },
    series: [
      {
        // name: 'Access From',
        type: 'pie',
        radius: '45%',
        bottom: '12%',
        center: ['20%', '35%'],

        // top: '0%',
        // left: '0%',
        // right: '0%',
        // bottom: '15%',
        avoidLabelOverlap: true,
        label: {
          show: true,
          position: 'left',
          formatter: function (params) {
            // Show the percentage value with one decimal point
            return params.percent.toFixed(1) + '%';
          },
          position: 'inside',
          textStyle: {
            fontSize: '8',
            color: "#fff"
          }
        },
        // emphasis: {
        //   label: {
        //     show: true,
        //     fontSize: 10,
        //     fontWeight: 'bold'
        //   }
        // },
        labelLine: {
          show: false
        },
        data: Year == 2025 ? [
          {
            value: generalRevenues[Year]?.["Property Taxes"], name: 'Property Taxes',
            itemStyle: { color: '#2b79b3' }
          },
          {
            value: generalRevenues[Year]?.["Non Property Taxes"], name: 'Non Property Taxes',
            itemStyle: { color: '#8C33FF' }
          },
          {
            value: generalRevenues[Year]?.["Charges for services"], name: 'Charges for services',
            itemStyle: { color: '#6f2e66' }
          },
          {
            value: generalRevenues[Year]?.["Use of money and property"], name: 'Use of money and property',
            itemStyle: { color: '#359760' }
          },
          {
            value: generalRevenues[Year]?.["State sources"], name: 'State sources',
            itemStyle: { color: '#2b3d82' }
          },
          {
            value: generalRevenues[Year]?.["Federal sources"], name: 'Federal sources',
            itemStyle: { color: '#402B50' }
          },
          {
            value: generalRevenues[Year]?.["Other"], name: 'Other',
            itemStyle: { color: '#4DB6AC ' }
          },
        ] : [
          // Default data for all other years
          {
            value: generalRevenues[Year]?.["Property Taxes"], name: 'Property Taxes',
            itemStyle: { color: '#2b79b3' }
          },
          {
            value: generalRevenues[Year]?.["Non Property Taxes"], name: 'Non Property Taxes',
            itemStyle: { color: '#2b3d82' }
          },
          {
            value: generalRevenues[Year]?.["State and Federal Aid"], name: 'State and Federal Aid',
            itemStyle: { color: '#6f2e66' }
          },
          {
            value: generalRevenues[Year]?.["Investment Earnings"], name: 'Investment Earnings',
            itemStyle: { color: '#359760' }
          },
          {
            value: generalRevenues[Year]?.["Compensation for Loss"], name: 'Compensation For Loss',
            itemStyle: { color: '#2C7AB8' }
          },
          {
            value: generalRevenues[Year]?.["Miscellaneous"], name: 'Miscellaneous',
            itemStyle: { color: '#402B50' }
          }
        ]
      }
    ]
  };

  const FinInfo2 = {
    // tooltip: {
    //   trigger: 'item',
    //   backgroundColor: 'rgba(50,50,50,0.7)',
    //   borderColor: "#333",
    //   textStyle: {
    //     fontSize: 12,
    //     fontWeight: "normal",
    //     color: '#fff',
    //   },
    //   axisPointer: {
    //     type: 'shadow'
    //   }

    // },
    tooltip: {
      extraCssText: 'width: 50%; white-space: pre-wrap;',
      trigger: "item",
      formatter: function (params) {
        return (
          params.name + " : " + params.percent.toFixed(1) + "%" + " (" + toMillion(params.value) + ")"
        );
      },
      axisPointer: {
        type: 'shadow'
      },
      confine: true
    },
    legend: {
      itemGap: 4,
      orient: 'vertical',
      right: screenWidth <= 1450 && screenWidth >= 865 ? '22%' : '20%',
      top: '6%',
      itemWidth: 6,
      itemHeight: 6,
      formatter: function (name) {
        // Customize legend item text here, wrap text and show remaining text as dotted
        const maxLength = screenWidth <= 1450 && screenWidth >= 865 ? 20 : 33; // maximum characters before truncating
        if (name.length > maxLength) {
          return name.slice(0, maxLength) + '...'; // show first maxLength characters and append '...'
        } else {
          return name;
        }
      },
      textStyle: {
        color: DarkMode ? "White" : 'black',
        fontSize: '11',
        fontStyle: 'normal',
        fontFamily: 'Roboto, sans-serif',
      }
    },


    series: [
      {
        // name: 'Access From',
        type: 'pie',
        radius: '45%',
        bottom: '12%',
        center: ['20%', '30%'],
        avoidLabelOverlap: true,
        label: {
          show: true,
          position: 'left',
          formatter: function (params) {
            // Show the percentage value with one decimal point
            return params.percent.toFixed(1) + '%';
          },
          position: 'inside',
          textStyle: {
            fontSize: '8',
            color: "#fff"
          }
        },
        // emphasis: {
        //   label: {
        //     show: true,
        //     fontSize: 10,
        //     fontWeight: 'bold'
        //   }
        // },
        labelLine: {
          show: false
        },
        data: Year == 2025 ? [
          {
            value: programExpense[Year]?.["General Support"], name: 'General Support',
            itemStyle: { color: '#2b79b3' }
          },
          {
            value: programExpense[Year]?.["Instruction"], name: 'Instruction',
            itemStyle: { color: '#2b3d82' }
          },
          {
            value: programExpense[Year]?.["Pupil transportation"], name: 'Pupil transportation',
            itemStyle: { color: '#6f2e66' }
          },
          {
            value: programExpense[Year]?.["Community Services"], name: 'Community Services',
            itemStyle: { color: '#359760' }
          },
          {
            value: programExpense[Year]?.["Employee benefits"], name: 'Employee benefits',
            itemStyle: { color: '#2C7AB8' }
          },
          {
            value: programExpense[Year]?.["Capital outlay"], name: 'Capital outlay',
            itemStyle: { color: '#402B50' }
          },
          {
            value: programExpense[Year]?.["Other"], name: 'Other',
            itemStyle: { color: '#4DB6AC' }
          }
        ] : [
          // Default data for all other years
          {
            value: programExpense[Year]?.["General Support"], name: 'General Support',
            itemStyle: { color: '#2b79b3' }
          },
          {
            value: programExpense[Year]?.["Instruction"], name: 'Instruction',
            itemStyle: { color: '#2b3d82' }
          },
          {
            value: programExpense[Year]?.["Pupil transportation"], name: 'Pupil transportation',
            itemStyle: { color: '#6f2e66' }
          },
          {
            value: programExpense[Year]?.["Community Services"], name: 'Community Services',
            itemStyle: { color: '#359760' }
          },
          {
            value: programExpense[Year]?.["School lunch"], name: 'School lunch',
            itemStyle: { color: '#2C7AB8' }
          },
          {
            value: programExpense[Year]?.["Interest"], name: 'Interest',
            itemStyle: { color: '#402B50' }
          }
        ]
      }
    ]
  };

  const RevenueExpense = {
    title: {
      // text: 'Revenue and Expense over the year',
      text: '',
      left: 10,
      textStyle: {
        fontSize: 12,
        fontWeight: "normal",
        color: DarkMode ? 'White' : 'black',
      },
    },
    // legend: {
    //   data: ['Revenue', 'Expense'],
    //   top: 0,
    //   right: 0,
    //   right: "40%",
    //   // bottom:"10%",
    //   itemWidth: 6,
    //   itemHeight: 6,
    //   top: '24%',
    //   textStyle: {
    //     color: DarkMode ? 'White' : 'black',
    //     fontSize: '12',
    //     fontStyle: 'normal',
    //     fontFamily: 'Roboto, sans-serif',
    //   },
    // },


    // tooltip: {

    //   backgroundColor: 'rgba(50,50,50,0.7)',
    //   borderColor: "#333",
    //   textStyle: {
    //     fontSize: 12,
    //     fontWeight: "normal",
    //     color: '#fff',
    //   },
    //   trigger: 'axis',
    //   // formatter: function(params) {
    //   //   return (
    //   //     params.name + " : " + toMillion(params.value)
    //   //   );
    //   // },
    //   axisPointer: {
    //     type: 'line',
    //     lineStyle: {
    //       type: "line"
    //     }
    //   },


    // },
    // grid: {
    //   top: 0,
    //   height: 10,
    //   left: -5,
    //   right: 10,
    //   tooltip: {
    //     padding: [50, 100, 100, 100],
    //     color: '#fff'
    //   }
    // },

    xAxis: [
      {
        type: 'category',
        // boundaryGap: false,
        data: ['2020', '2021', '2022', '2023'],
        splitLine: { show: false },
        axisLabel: {
          inside: false,
          color: DarkMode ? '#fff' : 'black',
          fontSize: 10, // Reduces the text size of the x-axis labels
          margin: 10,
          interval: 0,
        },
        axisLine: {
          show: false // Hides the x-axis line
        },
        axisTick: {
          show: false, // Disables vertical lines (ticks) before and after each x-axis value
        },
        // z: 10
      }
    ],

    yAxis: {
      show: false,
      type: 'value',
      min: 16000,
      // max: 22000,
    },

    series: [
      {
        data: [16901.72, 17795.55, 19707.64, 21416.43],
        type: 'line',
        label: {
          show: true,              // Enable the label display
          position: 'top',          // Position the label above each data point
          formatter: (params) => {
            const value = params.data.toFixed(1); // Format to one decimal place
            return `$${value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`; // Add commas
          },
          color: DarkMode ? '#fff' : 'black',  // Text color based on mode
          fontSize: 10,
          // offset: [0, 5]
        }
      }
    ],

    tooltip: {
      trigger: 'axis',
      backgroundColor: DarkMode ? "black" : "white",
      // backgroundColor: 'rgba(50,50,50,0.7)',
      borderColor: "#333",
      textStyle: {
        fontSize: 14,
        fontWeight: "normal",
        color: '#fff',
      },
      formatter: (params) => {
        return params.map(item =>
          `$${item.data.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
        ).join('');
      }
    },
    // grid: {

    //   right: '0%',
    //   bottom: '0%',
    //   top: '0%',
    //   width: '150%',
    //   height: '120%',
    //   left: '-22%',
    //   containLabel: true
    // },

    // yAxis: [
    //  { type: 'value'}
    // ],
    // xAxis: {
    //   boundaryGap: false,
    //   type: 'category',
    //   data: ['2015', '2016', '2017', '2018'],

    // },
    // color: ['#359760','#2b3d82'],
    // series: [
    //   {
    //     data: [2000, 1000, 2000, 500],
    //     type: 'line',
    //     colorBy: "data",
    //     areaStyle: {},
    //     label: {
    //       show: true,
    //       position: 'top',
    //       textShadowBlur: 0,
    //       color: '#fff'
    //     },
    //   },
    //   {
    //     data: [1000, 500, 2000, 3000],
    //     type: 'line',
    //     colorBy: "data",
    //     areaStyle: {},
    //     label: {
    //       show: true,
    //       position: 'top',
    //       textShadowBlur: 0,
    //       color: '#fff'
    //     },
    //   },
    // ]
    grid: {
      left: '0%',
      right: '0%',
      top: '45%',
      bottom: '40%',
      width: '100%',
      // containLabel: true
    },
    // xAxis: [
    //   {
    //     type: 'category',
    //     boundaryGap: false,
    //     data : Object.keys(generalRevenues),
    //     // data: ['2019', '2020', '2021', '2022'],
    //     splitLine: { show: false },
    //     axisLabel: {
    //       inside: true,
    //       color: DarkMode ? '#fff' : 'black'
    //     },
    //     z: 10
    //   }
    // ],
    // yAxis: [
    //   {
    //     type: 'value', splitLine: { show: false }, axisLabel: {
    //       show: false
    //     }

    //   }
    // ],
    // color: ['#2b3d82', '#359760'],
    // series: [


    //   {
    //     name: 'Revenue',
    //     type: 'line',
    //     stack: 'Total',
    //     // areaStyle: {},
    //     label: {
    //       show: false,
    //       position: 'top',
    //       color: '#fff'
    //     },
    //     // emphasis: {
    //     //   focus: 'series'
    //     // },
    //     data : Object.values(generalRevenues).map((item)=> item["Total Revenue"])
    //     // data: [1777657207, 1757890245, 1775213465, 1851600000]
    //   },
    //   {
    //     name: 'Expense',
    //     type: 'line',
    //     stack: 'Total',
    //     label: {
    //       show: false,
    //       position: 'top',
    //       color: '#fff'
    //     },
    //     // areaStyle: {},
    //     // emphasis: {
    //     //   focus: 'series'
    //     // },
    //     data : Object.values(programExpense).map((item)=> item["TOTAL EXPENSES"])
    //     // data: [1706443552, 1672957566, 1800313940, 1806200000]
    //   }
    // ]
  };

  const percentageChange = ((generalRevenues[Year]?.["Total Revenue"] - (Year > 2020 ? generalRevenues[Year - 1]?.["Total Revenue"] : 0)) / (Year > 2020 ? generalRevenues[Year - 1]?.["Total Revenue"] : 1)) * 100;
  const percentageChange1 = ((programExpense[Year]?.["TOTAL EXPENSES"] - (Year > 2020 ? programExpense[Year - 1]?.["TOTAL EXPENSES"] : 0)) / (Year > 2020 ? programExpense[Year - 1]?.["TOTAL EXPENSES"] : 1)) * 100;



  return (
    <div className="EnrollmentTile text-[#fff]">
      <div className="relative h-[130px]">
        <div className=" h-[130px] ">
          <img src={Finance_bannerImg} className="h-[130px] w-full " />
        </div>
        <div className="w-full absolute bottom-0 h-[27px] bg-[#1d192f9c] py-1 px-3">
          <h3 className="text-[14px] font-[400] text-[#fff]">Financial Information</h3>
        </div>

      </div>
      {generalRevenues[Year]?.["Total Revenue"] > 0 ? (
        <>
          <div className="grid grid-cols-2 gap-5 pt-[8px] pl-[8px] pr-[8px] mt-1 mb-[0px]">
            <div className="flex items-start gap-3">

              <div className="pt-1 pb-2">
                <p className="dark:text-white text-[14px] font-[400] ">
                  Revenue</p>
                <div className="flex items-center gap-1">
                  {/* <h4 className="EnrollmentTitle text-[16px] text-[#fff] font-semibold">$1,851.6 M</h4> */}
                  <h4 className="text-[16px] font-semibold dark:text-[#fff] pt-1">{toMillion(generalRevenues[Year]?.["Total Revenue"])}</h4>
                  <div className={`h-4 pt-1 ${percentageChange >= 0 ? 'trendingup' : 'trendingdown'}`}>
                    <img src={percentageChange >= 0 ? TreadingarrowUp : TreadingarrowDown} className="" />{`${percentageChange.toFixed(1)}%`}
                  </div>
                </div>
              </div>
            </div>


            <div className="flex items-start gap-3">

              <div className="pt-1">
                <p className="dark:text-white text-[14px] font-[400]">
                  Expense</p>

                <div className="flex items-center gap-2">
                  <h4 className="text-[16px] font-semibold dark:text-[#fff] pt-1">{toMillion(programExpense[Year]["TOTAL EXPENSES"])}</h4>
                  <div className={`h-4 pt-1 ${percentageChange >= 0 ? 'trendingup' : 'trendingdown'}`}>
                    <img src={percentageChange >= 0 ? TreadingarrowUp : TreadingarrowDown} className="" /> {`${percentageChange1.toFixed(1)}%`}</div>
                </div>

              </div>


            </div>
          </div>



          <div className="grid grid-cols-1 gap-8 px-2 mt-0 border-t border-[#ffffff26]">
            <div className="FinInfo1">
              <ReactEcharts style={{ width: 320, height: 80 }} option={FinInfo1} />
            </div>
            <div className="FinInfo2">
              <ReactEcharts style={{ width: 320, height: 80 }} option={FinInfo2} />
            </div>
          </div>
          <div className="mb-0  RevenueExpense h-[40px]">
            <div className="EnrollmentTitle text-[14px] font-[400] text-left mx-3 mt-5">Per Pupil Expenditure</div>
            <ReactEcharts style={{ maxWidth: 280 }} option={RevenueExpense} />
          </div>

        </>
      ) : <div>
        <div className=" w-full h-[16.5rem] flex justify-center items-center text-gray-300/25">No Data</div>
        <div className="border-t border-[#ffffff26]">
          <div className="mb-0  RevenueExpense h-[40px]">
            <div className="EnrollmentTitle text-[14px] font-[400] text-left mx-3">Per Pupil Expenditure</div>
            <ReactEcharts style={{ maxWidth: 280 }} option={RevenueExpense} />
          </div>
        </div>
      </div>}


      {/* <div className="grid grid-cols-1 gap-10 px-2 mt-5 border-t border-[#ffffff26]">
        <div className="FinInfo2">
          <ReactEcharts style={{height:120}} option={FinInfo2} />
        </div>
      </div> */}





    </div>
  )
}