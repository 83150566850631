import { Dropdown } from 'primereact/dropdown';
import { Carousel } from 'primereact/carousel';
import Ignitelogo from "../../assets/images/rocket.svg";
import 'primeicons/primeicons.css';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useState, useRef, useEffect } from 'react';
import LoaderContainer from "../../components/loaderContainer";

import VerticalChart from '../Charts/Verticalbarchart';
import { useDispatch, useSelector } from "react-redux";
import {
    fetchTimeTrend, fetchDimensionView, fetchGoalsAndStrategySchoolType
} from "../../redux/slices/goalsandstrategy"

export default function GoalsAndStrategySidebar({ Data, tile }) {
    console.log("Tile", tile)
    const dispatch = useDispatch();
    const TimeTrend = useSelector(state => state.goalsandstrategy.TimeTrend);
    const TimeTrendloading = useSelector(state => state.goalsandstrategy.TimeTrendloading);

    const DimensionView = useSelector(state => state.goalsandstrategy.DimensionView);
    const DimensionViewloading = useSelector(state => state.goalsandstrategy.DimensionViewloading);

    const GoalsAndStrategySchoolType = useSelector(state => state.goalsandstrategy.GoalsAndStrategySchoolType);
    const GoalsAndStrategySchoolTypeloading = useSelector(state => state.goalsandstrategy.GoalsAndStrategySchoolTypeloading);

    // const Dropdown1Opts = [
    //     {name:"K-8 ELA Iready Assessment",code:"metric1"},
    //     {name:"K-8 Math Iready Assessment",code:"metric2"},
    //     {name:"SWD Inclusion",code:"metric3"},
    //     {name:"9th Grade Credit Accrual",code:"metric4"},
    //     {name:"Professional Learning Participation",code:"metric5"},
    // ]


    // const ZoneData = [
    //     {header:"North Zone",Current:1,Variance:2},
    //     {header:"South Zone",Current:5,Variance:-2},
    //     {header:"West Zone",Current:4,Variance:-2},
    //     {header:"North East Zone",Current:2,Variance:2},
    //     {header:"Overall",Current:2,Variance:2},
    // ]

    let yeardata = JSON.parse(sessionStorage.getItem("Year")) == undefined || null ? "2023" : JSON.parse(sessionStorage.getItem("Year"))

    let annualGraduationCCR = {
        "2018": 147.14,
        "2019": 150.46,
        // "2020" : 0,
        // "2021" : 0,
        "2022": 161.58,
        "2023": 155.58,
        // "2024" : 0,
    }

    const AnnualCCRMockChartData = Object.entries(annualGraduationCCR).map(([key, value]) => (
        {
            "METRIC_ID": 10090,
            "SCHOOL_YEAR": key,
            "METRIC_NAME": "Annual CCCR Index",
            "METRIC_DESCRIPTION": "This indicator gives the student proficient in NWEA ELA benchmark assessment",
            "METRIC_GROUP": "Assessment",
            "METRIC_GROUP_ORDER": 4,
            "METRIC_GROUP_DESCRIPTION": "Student level statistics on NWEA Assessment",
            "METRIC_GROUP_ICON_LOCATION": "/metric_group_icon_location/Assessment.jpg",
            "METRIC_DISPLAY_ORDER": 6,
            "METRIC_ICON_LOCATION": "/metric_icon_location/student NWEA ELA.jpg",
            "METRIC_MET_LABEL": "% of student above NWEA ELA benchmark",
            "METRIC_NOT_MET_LABEL": null,
            "METRIC_TYPE": "number",
            "METRIC_SUFFIX": null,
            "METRIC_PREFIX": null,
            "METRIC_MASK": "###,###",
            "METRIC_PRECISION": 1,
            "METRIC_INDICATOR_TYPE": "positive",
            "CURRENT_PERIOD": value,
            "LAST_PERIOD": 0,
            "LAST_PERIOD_VARIANCE": 10900
        }
    ))

    const ZoneTemplate = (data) => {
        return (
            <>
                <div class="mr-4 bg-[#1f2a37] rounded select-none">
                    <div class="flex justify-between bg-[#42536D] rounded-[2px] px-[12px] py-[10px]">
                        <div class="text-[#E5E7EB] text-[12px] md:text-[14px] lg:text-[16px] xl:text-[18px] 3xl:text-[0.938vw] leading-6">{data?.SCHOOL_TYPE}</div>
                        {/* <div class="text-[#F37070] text-[10px] 3xl:text-[0.521vw]"><i class="ged-dots"></i></div> */}
                        <div class={`rounded-full h-[20px] xl:h-[10px] 3xl:h-[1.042vw] xl:w-[10px] w-[20px] 3xl:w-[1.042vw] ${(data?.METRIC_INDICATOR_TYPE == 'positive' === data?.TARGET_PERCENTAGE < data?.CURRENT_PERIOD) ? 'bg-[#5bd96c]' : 'bg-[#d9685b]'}`}></div>

                    </div>
                    <div class="px-[12px] py-[5px] flex justify-between gap-[15px]">
                        <div class="p-[5px]">
                            <div class="text-[#E5E7EB] text-xs font-normal leading-4 3xl:text-[0.625vw] 3xl:leading-[0.833vw]">Current</div>
                            <div class="text-[#E5E7EB] text-xl font-bold leading-7 3xl:text-[1.25vw] 3xl:leading-[1.458vw]">{data?.METRIC_TYPE == "number" ? data?.CURRENT_PERIOD : data?.CURRENT_PERIOD == 100 ? data?.CURRENT_PERIOD?.toFixed(0) + '%' : data?.CURRENT_PERIOD?.toFixed(1) + '%'}</div>
                        </div>
                        <div class="p-[5px]">
                            <div class="text-[#E5E7EB] text-xs font-normal leading-4 3xl:text-[0.625vw] 3xl:leading-[0.833vw]">LY Var</div>
                            <div class="text-[#E5E7EB] text-xl flex items-center gap-2 mt-2 font-bold leading-4 3xl:text-[1.25vw] 3xl:leading-[1.458vw]">
                                {(data?.LAST_PERIOD_VARIANCE <= 100) ? `${data?.LAST_PERIOD_VARIANCE?.toFixed(1)}%` : "-"} {data?.LAST_PERIOD_VARIANCE > 0 ?
                                    <div class="w-0 h-0 border-l-8 border-l-transparent border-b-8 border-b-green-400 border-r-8 border-r-transparent"></div>
                                    :
                                    <div class="w-0 h-0 border-l-8 border-l-transparent border-t-8 border-t-red-400 border-r-8 border-r-transparent"></div>} </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    // const [selectedMetric,setSelectedMetric] = useState({METRIC_NAME:"K-8 ELA Iready Assessment",code:"metric1"},)
    const [selectedMetric, setSelectedMetric] = useState(tile.value)

    const Dropdown1Opts = Data
    const [Dropdown2Opts, setDropdown2Opts] = useState([])
    useEffect(() => {
        let opt = tile?.title == "Cultivate Trust" || ['Student Perception', 'Instructional Perception', 'Family Perception', 'SEL Panorama'].some(x => (selectedMetric?.METRIC_NAME).startsWith(x)) ?
            [
                { name: "School Type", code: "SCHOOL_TYPE" },
                { name: "School Name", code: "SCHOOL_OFFICIAL_NAME" },
            ]
            :
            [
                { name: "School Type", code: "SCHOOL_TYPE" },
                { name: "Gender", code: "STUDENT_GENDER" },
                { name: "Ethnicity", code: "STUDENT_ETHNICITY" }
            ]
        setDropdown2Opts(opt)
    }, [tile, selectedMetric])

    const [selectedSchool, setSelectedSchool] = useState({ name: "School Type", code: "SCHOOL_TYPE" })
    const [carouselpage, setcarouselpage] = useState(0);
    const carousel = useRef(null)
    // const carouselMaxPage = ZoneData.length - carousel.current?.props.numVisible
    const carouselMaxPage = (typeof carousel.current?.props.numVisible == 'number') ? GoalsAndStrategySchoolType.length - carousel.current?.props.numVisible : GoalsAndStrategySchoolType.length - 4
    const carouselNav = {
        prev: () => {
            if (carouselpage > 0) { setcarouselpage(carouselpage - 1) }
        },
        next: () => {
            if (carouselMaxPage > carouselpage) { setcarouselpage(carouselpage + 1) }
        }
    }
    const [activeTab, setactiveTab] = useState(0)

    // API Calls
    useEffect(() => {
        dispatch(fetchTimeTrend({
            "elasticQueryName": "",
            "filters": [
                { "columnName": "METRIC_NAME", "columnValue": [`${selectedMetric?.METRIC_NAME}`], "excludeKeyword": false }
            ],
            "dynamicColumns": [],
        }));

        dispatch(fetchGoalsAndStrategySchoolType({
            "elasticQueryName": "",
            "filters": [{
                "columnName": "SCHOOL_YEAR",
                "columnValue": ["2025"],
                "excludeKeyword": false
            },
            { "columnName": "METRIC_ID", "columnValue": [`${selectedMetric?.METRIC_ID}`], "excludeKeyword": false }
            ],
            "dynamicColumns": [],
        }));


    }, [selectedMetric, tile])

    useEffect(() => {
        dispatch(fetchDimensionView({
            "elasticQueryName": "",
            "filters": [
                { "columnName": "METRIC_NAME", "columnValue": [`${selectedMetric?.METRIC_NAME}`], "excludeKeyword": false }
            ],
            "dynamicColumns": [
                { "columnName": "#{dimension}", "columnValue": `${selectedSchool.code}` }
            ],
        }));
    }, [selectedSchool, selectedMetric, tile])
    // 

    // HANDLE CHART DATA
    const handleChartData = (data = [], label, name, value, addRow) => {
        let names;
        names = [...new Set(data.map(x => x[name]))].sort()
        names = Object.fromEntries(names.map(x => {
            return [x, 0]
        }))
        var item = data.reduce((a, b) => {
            a[b[label]] = a[b[label]] || [];
            a[b[label]].push({ ...{ [b[name]]: (b[value]) } });
            return a;
        }, {});
        // let Cate = items
        var array = Object.keys(item).map(function (k) {
            // console.log({...names,...Object.assign(...item[k])})

            return { ['label']: k, values: { ...names, ...Object.assign(...item[k]) } };
        });
        return array.sort();

    }
    console.log('Di', handleChartData(DimensionView, ['SCHOOL_YEAR'], ['SCHOOL_TYPE'], ['CURRENT_PERIOD']))
    console.log('Trend', handleChartData(DimensionView, ['SCHOOL_YEAR'], ['METRIC_NAME'], ['CURRENT_PERIOD']))

    // 
    return (
        <>
            {/* <div className='h-full bg-[#111928]'> */}
            <div class="px-[15px] xl:px-[14px] 3xl:px-[0.781vw] py-[16px] xl:py-[14px] 3xl:py-[0.833vw] bg-[#1F2A37]">
                <div class="flex items-center justify-between gap-[25px] xl:gap-[20px] 3xl:gap-[1.302vw]">
                    <div class="flex items-center w-full justify-between">
                        <div className='flex'>
                            <div class="pr-[25px] 3xl:pr-[1.302vw]">
                                <img
                                    alt=""
                                    loading="lazy"
                                    width="42"
                                    height="42"
                                    decoding="async"
                                    data-nimg="1"
                                    class="w-[42px] xl:w-[38px] 3xl:w-[2.188vw] h-[42px] xl:h-[38px] 3xl:h-[2.188vw]"
                                    src={Ignitelogo}
                                    style={{ color: "transparent" }}
                                />
                            </div>
                            <div class="text-[#E5E7EB] text-[24px] xl:text-[20px] 3xl:text-[1.25vw] font-light">
                                {tile?.title?.split(" ")?.slice(0, -1)?.join(" ")} <span class="font-bold">{tile?.title?.split(" ")?.slice(-1)}</span>
                            </div>
                        </div>
                        <div class="text-[#E5E7EB] text-[18px] xl:text-[12px] 3xl:text-[1vw] font-medium">
                            We are committed to fostering learning environments that promote
                            student engagement, belonging and academic growth.
                        </div>
                    </div>
                    {/* <div class="flex items-center gap-3 3xl:gap-[0.921vw]">
                    <div class="bg-[#84E1BC] rounded-full h-[20px] xl:h-[18px] 3xl:h-[1.042vw] xl:w-[18px] w-[20px] 3xl:w-[1.042vw]"></div>
                    <div>
                    <div class="text-[#E5E7EB] text-[12px] 3xl:text-[0.725vw] font-normal">
                        Status
                    </div>
                    <div class="text-[#E5E7EB] text-[24px] xl:text-[22px] 3xl:text-[1.25vw] font-bold leading-none">
                        87%
                    </div>
                    </div>
                </div> */}
                </div>
            </div>
            <div class="bg-[#263040] border-l-[25px] xl:border-l-[28px] 3xl:border-l-[1.527vw] border-[#84E1BC]">
                <div class="flex items-center justify-between px-[15px] xl:px-[14px] 3xl:px-[0.781vw]">
                    <div class="text-[#D9D9D9] text-[18px] xl:text-[16px] 3xl:text-[0.938] py-[10px] 3xl:py-[0.533vw]">{selectedMetric?.METRIC_NAME}</div>
                    <div class="flex gap-10 ">
                        <div class="p-[5px] py-[10px] 3xl:py-[0.533vw]">
                            <div class="text-[#D9D9D9] text-[11px] 3xl:text-[0.725vw] font-normal">Current</div>
                            <div class="text-[#D9D9D9] text-[24px] xl:text-[22px] 3xl:text-[1.25vw] font-bold leading-none">{selectedMetric?.METRIC_TYPE == "number" ? `${selectedMetric?.CURRENT_PERIOD}` : `${selectedMetric?.CURRENT_PERIOD?.toFixed(1) || "-"}%`}</div>
                        </div>
                        <div class="p-[5px] py-[10px] 3xl:py-[0.533vw]">
                            <div class="text-[#D9D9D9] text-[11px] 3xl:text-[0.725vw] font-normal">LY Var</div>
                            <div class="text-[#D9D9D9] text-[28px] xl:text-[16px] 3xl:text-[0.833vw] font-bold mt-2 leading-none flex items-center gap-3"><span>{(selectedMetric?.LAST_PERIOD_VARIANCE <= 100) ? `${selectedMetric?.LAST_PERIOD_VARIANCE?.toFixed(1) || "-"}%` : "-"}</span>{selectedMetric?.LAST_PERIOD_VARIANCE > 0 ? <div class="w-0 h-0 border-l-8 border-l-transparent border-b-8 border-b-green-400 border-r-8 border-r-transparent"></div> : <div class="w-0 h-0 border-l-8 border-l-transparent border-t-8 border-t-red-400 border-r-8 border-r-transparent"></div>}</div>
                        </div>
                        <div class="bg-[#374151] flex items-center px-[12px]">
                            <Dropdown
                                optionLabel="METRIC_NAME"
                                value={selectedMetric}
                                options={Dropdown1Opts}
                                onChange={(e) => setSelectedMetric(e.value)}
                                placeholder="Change Metric"
                                panelClassName='custdropdown'
                                className='custdropdown !w-[8vw] !md:w-[12vw] !3xl:w-[6.875vw] !text-white'
                            />
                        </div>
                    </div>
                </div>
            </div>
            {selectedMetric?.METRIC_NAME != "Annual CCCR Index" &&
                <div class="slider-container pt-[10px] md:pt-[20px] 3xl:pt-[1.042vw] bg-[#ffffff05] mt-[20px] px-[20px]">
                    <div class="flex justify-between items-center gap-[10px] mb-5">
                        <div class="flex justify-between items-center gap-[10px]">
                            <i class="pi pi-chevron-circle-up text-[18px] text-[#E5E7EB]"></i>
                            {/* <i class="ged-arrow-circle-up text-[18px] text-[#E5E7EB]"></i> */}
                            <div class="text-[#fff] text-[18px]">School</div>
                        </div>
                        <div class="border border-dashed border-[#374151] w-full"></div>
                        <div class="text-right flex justify-end gap-[6px] pr-[10px] md:pr-[15px] xl:pr-[20px] 3xl:pr-[1.042vw]">
                            <i className="pi pi-arrow-circle-left text-[#E5E7EB] text-[18px]" onClick={carouselNav.prev}></i>
                            {/* <a class="button" href=""><i class="ged-arrow-left-circule text-[#E5E7EB] text-[18px]"></i></a> */}
                            {/* <a class="button" href=""><i class="ged-arrow-right-circule text-[#E5E7EB] text-[18px]"></i></a> */}
                            <i class="pi pi-arrow-circle-right text-[#E5E7EB] text-[18px]" onClick={carouselNav.next}></i>
                        </div>
                    </div>
                    <LoaderContainer loading={GoalsAndStrategySchoolTypeloading}>
                        <Carousel
                            ref={carousel}
                            // value={ZoneData}
                            value={GoalsAndStrategySchoolType}
                            itemTemplate={ZoneTemplate}
                            numVisible={4}
                            numScroll={1}
                            page={carouselpage}
                        // onPageChange={(e)=>setcarouselpage(e.page)}
                        />
                    </LoaderContainer>
                </div>
            }
            <div class="px-[30px] xl:px-[38px] 3xl:px-[2.135vw]">
                {/* <div class="flex items-center my-[24px] xl:my-[22px] 3xl:my-[1.25vw]">
                    <button class="text-[#D1D5DB] bg-[#1570EF]  rounded-l-[4px] px-[16px] xl:px-[14px] 3xl:px-[0.833vw] py-[8px] text-[14px] xl:text-[12px] 3xl:text-[0.729vw]">Time Trend</button>
                    <button class="text-[#D1D5DB] bg-[#1F2A37] rounded-r-[4px] px-[16px] xl:px-[14px] 3xl:px-[0.833vw] py-[8px] text-[14px] xl:text-[12px] 3xl:text-[0.729vw]">Dimension View</button>
                </div> */}
                <Tabs selectedIndex={activeTab} onSelect={i => setactiveTab(i)}>
                    <TabList className='flex gap-1 items-center my-[24px] xl:my-[22px] 3xl:my-[1.25vw] select-none cursor-pointer'>
                        <Tab className={`text-[#D1D5DB] ${(activeTab === 0) ? 'bg-[#1570EF]' : 'bg-[#1F2A37]'} rounded-l-[4px] px-[16px] xl:px-[14px] 3xl:px-[0.833vw] py-[8px] text-[14px] xl:text-[12px] 3xl:text-[0.729vw]`}>Time Trend</Tab>
                        {selectedMetric?.METRIC_NAME != "Annual CCCR Index" &&
                            <Tab className={`text-[#D1D5DB] ${(activeTab === 1) ? 'bg-[#1570EF]' : 'bg-[#1F2A37]'} rounded-r-[4px] px-[16px] xl:px-[14px] 3xl:px-[0.833vw] py-[8px] text-[14px] xl:text-[12px] 3xl:text-[0.729vw]`}>Dimension View</Tab>
                        }
                    </TabList>
                    <div className='backdrop-sepia-[7.5px] bg-[#11192899] p-[16px] 3xl:p-[0.833vw] border border-[#4B5563] rounded-[16px] 3xl:rounded-[0.833vw] h-full text-white'>
                        <TabPanel>
                            <div class="flex items-center justify-between pb-[16px] xl:pb-[0.833vw]">
                                <div class="text-[#FFF] dark:text-[#FFF] text-[18px] xl:text-[0.938vw] font-medium">
                                    <p>Time Trend</p>
                                </div>
                            </div>
                            <div>
                                {/* <div class="flex justify-end mb-2">
                                    <Dropdown 
                                        optionLabel="name" 
                                        value={selectedSchool} 
                                        options={Dropdown2Opts} 
                                        onChange={(e) => setSelectedSchool(e.value)} 
                                        placeholder="School Type"
                                        className='custdropdown2 !w-[130px] !md:w-[150px] !3xl:w-[6.875vw] !text-white'
                                        panelClassName='custdropdown'
                                    />
                                </div> */}
                                <div className='w-full  rounded-lg bg-[#1F2A37]'>
                                    <LoaderContainer loading={TimeTrendloading}>
                                        <div className='p-2 3xl:h-[15.625vw] text-white'>
                                            <VerticalChart data={handleChartData(selectedMetric?.METRIC_NAME == "Annual CCCR Index" ? AnnualCCRMockChartData : TimeTrend, ['SCHOOL_YEAR'], ['METRIC_NAME'], ['CURRENT_PERIOD'])} valueType={selectedMetric?.METRIC_TYPE} />
                                        </div>
                                    </LoaderContainer>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div class="flex items-center justify-between pb-[16px] xl:pb-[0.833vw]">
                                <div class="text-[#FFF] dark:text-[#FFF] text-[18px] xl:text-[0.938vw] font-medium">
                                    <p>Dimension View</p>
                                </div>
                            </div>
                            <div>
                                <div class="flex justify-end mb-2">
                                    <Dropdown
                                        optionLabel="name"
                                        value={selectedSchool}
                                        options={Dropdown2Opts}
                                        onChange={(e) => setSelectedSchool(e.value)}
                                        placeholder="School Type"
                                        className='custdropdown2'
                                        panelClassName='custdropdown'
                                    />
                                </div>
                                <div className='w-full  rounded-lg bg-[#1F2A37]'>
                                    <LoaderContainer loading={DimensionViewloading}>
                                        <div className='p-2 3xl:h-[15.625vw] text-white'>
                                            <VerticalChart
                                                legends={{
                                                    show: true, type: 'scroll', bottom: 10, itemHeight: 10,
                                                    itemWidth: 10, symbol: 'line',
                                                    textStyle: {
                                                        color: 'white',
                                                        fontSize: 9,
                                                    },
                                                    pageButtonItemGap: 5,
                                                    left: 'center',
                                                    height: 50,
                                                    pageButtonPosition: 'end',
                                                    width: '80%'
                                                }}
                                                data={handleChartData(DimensionView, ['SCHOOL_YEAR'], [`${selectedSchool.code}`], ['CURRENT_PERIOD'])}
                                                valueType={selectedMetric?.METRIC_TYPE} />
                                        </div>
                                    </LoaderContainer>
                                </div>
                            </div>
                        </TabPanel>
                    </div>
                </Tabs>
            </div>
            {/* </div> */}
        </>
    )
}