import React, { useState, useEffect } from 'react'
import TopNav from '../components/common/topnav.component'
import k12darklogo from '../assets/images/GoalsStrategyLogo.svg';
import Complogo from '../assets/images/GoalsStrategyLogo.svg';
import Ignitelogo from "../assets/images/rocket.svg";
import Cultivatelogo from "../assets/images/together.svg";
import Expandlogo from "../assets/images/direction.svg";
import { Dropdown } from "primereact/dropdown"
import { InputText } from 'primereact/inputtext';
import 'primeicons/primeicons.css';
import GoalsAndStrategySidebar from '../components/Popups/GoalsStrategySidebar.js'
import { Sidebar } from 'primereact/sidebar';
import { useDispatch, useSelector } from "react-redux";
import { AutoComplete } from "primereact/autocomplete"
import {
  fetchIgniteLearning, fetchCultivateTrust, fetchSearchfilter, fetchExpandedOportunity
} from '../redux/slices/goalsandstrategy.js';

function GoalsAndStrategy(props) {
  const dispatch = useDispatch();

  const IgniteLearning = useSelector(state => state.goalsandstrategy.IgniteLearning);
  const IgniteLearningloading = useSelector(state => state.goalsandstrategy.IgniteLearningloading);

  const CultivateTrust = useSelector(state => state.goalsandstrategy.CultivateTrust);
  const CultivateTrustloading = useSelector(state => state.goalsandstrategy.CultivateTrustloading);

  const ExpandedOportunity = useSelector(state => state.goalsandstrategy.ExpandedOportunity);
  const ExpandedOportunityloading = useSelector(state => state.goalsandstrategy.ExpandedOportunityloading);

  const Searchfilter = useSelector(state => state.goalsandstrategy.Searchfilter);
  const Searchfilterloading = useSelector(state => state.goalsandstrategy.Searchfilterloading);

  const [selectedMetric, setSelectedMetric] = useState(null);
  const [selectedData, setSelectedData] = useState();

  const [filteredMetrics, setFilteredMetrics] = useState(null);


  const [filteredData, setFilteredData] = useState(null);

  const searchMetric = (event) => {
    setFilteredMetrics(Searchfilter.filter(metric =>
      metric.METRIC_NAME.toLowerCase().includes(event.query.toLowerCase())
    ));
    setSelectedData([selectedMetric])
  };


  /* dummy data */
  const CultivateTrustDummy = [
    {
      "METRIC_ID": 0,
      "METRIC_NAME": "Family Perception Survey",
      "METRIC_TYPE": "number",
      "METRIC_INDICATOR_TYPE": "positive",
      "CURRENT_PERIOD": "-",
      "LAST_PERIOD": 97.8676,
      "LAST_PERIOD_VARIANCE": "-",
    },
    {
      "METRIC_ID": 0,
      "METRIC_NAME": "Student Perception Survey",
      "METRIC_TYPE": "number",
      "METRIC_INDICATOR_TYPE": "positive",
      "CURRENT_PERIOD": "-",
      "LAST_PERIOD": 98.8676,
      "LAST_PERIOD_VARIANCE": "-"
    },
    {
      "METRIC_ID": 0,
      "METRIC_NAME": "Instructional Perception Survey",
      "METRIC_TYPE": "number",
      "METRIC_INDICATOR_TYPE": "positive",
      "CURRENT_PERIOD": "-",
      "LAST_PERIOD": 96.8676,
      "LAST_PERIOD_VARIANCE": "-"
    },
  ]

  let yeardata = JSON.parse(sessionStorage.getItem("Year")) == undefined || null ? "2024" : JSON.parse(sessionStorage.getItem("Year"))

  let annualGraduationCCR = {
    "2018": 147.14,
    "2019": 150.46,
    // {"2020" : '-'},
    // {"2021" : '-'},
    "2022": 161.58,
    "2023": 155.58,
    "2024": 161.48
  }
  const ExpandedopertunityDummy = [
    {
      "METRIC_ID": "mockdata",
      "METRIC_NAME": "Annual CCCR Index",
      "METRIC_TYPE": "number",
      "METRIC_INDICATOR_TYPE": "positive",
      "CURRENT_PERIOD": annualGraduationCCR[`${yeardata}`]?.toFixed(1) || "-",
      "LAST_PERIOD": 97.8676,
      "LAST_PERIOD_VARIANCE": annualGraduationCCR[`${yeardata}`] ? (((annualGraduationCCR[`${yeardata}`] - (annualGraduationCCR[`${yeardata - 1}`] || 0)) / (annualGraduationCCR[`${yeardata - 1}`] || 1)) * 100) : 0
    }
  ]
  /* */

  // API calls
  useEffect(() => {
    dispatch(fetchIgniteLearning({
      "elasticQueryName": "",
      "filters": [{
        "columnName": "SCHOOL_YEAR",
        "columnValue": ["2025"],
        "excludeKeyword": false
      }],
      "dynamicColumns": [],
    }));

    dispatch(fetchCultivateTrust({
      "elasticQueryName": "",
      "filters": [{
        "columnName": "SCHOOL_YEAR",
        "columnValue": ["2025"],
        "excludeKeyword": false
      }],
      "dynamicColumns": [],
    }));

    dispatch(fetchExpandedOportunity({
      "elasticQueryName": "",
      "filters": [{
        "columnName": "SCHOOL_YEAR",
        "columnValue": ["2025"],
        "excludeKeyword": false
      }],
      "dynamicColumns": [],
    }));

    dispatch(fetchSearchfilter({
      "elasticQueryName": "",
      "filters": [{
        "columnName": "SCHOOL_YEAR",
        "columnValue": ["2025"],
        "excludeKeyword": false
      }],
      "dynamicColumns": [],
    }));
  }, [])
  // 

  const [dropdownOptions, setDropdownOptions] = useState({ code: 0, name: "All Status" });

  const options = [
    { code: 0, name: "All Status" },
    { code: 1, name: "Status 1" },
    { code: 2, name: "Status 2" },
    { code: 3, name: "Status 3" },
    { code: 4, name: "Status 4" },
    { code: 5, name: "Status 5" },
  ]

  const [selectedtile, setSelectedtile] = useState(null);
  const [visible, setVisible] = useState(false);

  const HandleSelectTile = (title, value) => {
    setSelectedtile({ title: title, value: value })
  }
  const HandlePrpsData = () => {
    let name = selectedtile?.title;
    if (name == "Ignite Learning") {
      return IgniteLearning;
    }
    else if (name == "Cultivate Trust") {
      return CultivateTrust;
    }
    else if (name == "Expanded Oportunity") {
      return [...ExpandedOportunity, ...ExpandedopertunityDummy
        // ?.filter(x=>x?.METRIC_ID == 'mockdata')
      ];
    }
    else {
      return [];
    }
  }

  return (
    <>
      <Sidebar
        visible={visible}
        onHide={() => setVisible(false)}
        position='right'
        // className='w-[80%]'
        className='customsidebar !w-[56.771vw] !lg:w-[80%] !xl:w-[56.771vw] !bg-[#111928]'
      // header = {()=>null}
      // iconsTemplate={() => <>
      // <div className='absolute h-0'>

      // </div>
      // </>}
      >
        <GoalsAndStrategySidebar Data={HandlePrpsData()} tile={selectedtile} />
      </Sidebar>

      <div className="pt-0 dashboard goalsandStrategy">
        <TopNav pagename="Strategic Plan Dashboard" pagenamedescription="Key Facts about the District" setUserData={props.setUserData} />


        {/* <div className='flex justify-between 3xl:pl-[6.615vw] lg:pl-[80px] pl-5'>
            <div className='3xl:pt-[1.406vw] pt-6'>
                <img src={Complogo} alt='innivelogo' width={228} height={91}/>
            </div>
            <div className='flex items-center 3xl:gap-[1.674vw] gap-7'>
                  <div className='text-white text-3xl 3xl:text-[2.188vw] font-normal'>Goals & Strategy</div>
                  <a><img  className='w-[72px] 3xl:w-[4.282vw] 3xl:h-[7.944vw]' style={{color :'transparent'}}/></a>
            </div>
      </div> */}

        <div class="3xl:py-[0.486vw] xl:py-[0px] py-11 3xl:px-[6.667vw] lg:px-[80px] px-5 ">
          <div class="flex items-center justify-between">
            <div class="text-white text-lg 3xl:text-[1.094vw] font-light leading-8 3xl:leading-[1.667vw] opacity-70"></div>
            <div class="flex items-center search_dropdown">
              {/* <Dropdown 
            value={dropdownOptions} 
            onChange={(e) => setDropdownOptions(e.value)} 
            options={options} 
            optionLabel="name" 
            placeholder={dropdownOptions?.name}
            panelClassName='custdropdown'
            className="w-full md:w-14rem h-[40px] "
             />  */}
              <div className="flex justify-end items-center relative mt-4">
                {/* <InputText value={value} onChange={(e) => setValue(e.target.value)}   
              className='h-[40px] 3xl:w-[17.917vw] w-[250px] text-white' 
              placeholder='Search....' /> */}
                {/* <AutoComplete 
                  value={selectedMetric} 
                suggestions={filteredMetrics} 
                completeMethod={searchMetric} 
                field="METRIC_NAME" 
                onChange={(e) => setSelectedMetric(e.value)} 
                panelClassName='custdropdown2' 
                // className=' w-[200px]'
                  /> 
              <i className='pi pi-search text-white pr-[1rem] absolute'></i>*/}
              </div>
            </div>
          </div>
          <div class="3xl:mt-[1.667vw] mt-7 space-y-[20px] 2xl:space-y-[1.25vw] 3xl:mb-[1.667vw] mb-7">

            <div class="bg-[rgba(255,255,255,0.04)] border border-[#2B3B4F] rounded-lg">
              <div class="bg-[#1F2A37] flex items-center gap-6 3xl:gap-[1.302vw] py-2 3xl:py-[0.417vw] px-3.5 3xl:px-[0.781vw] rounded-tl-lg rounded-tr-lg">
                {/* rocket logo img */}
                <div><img alt="manu" width="42" height="42" src={Ignitelogo} /></div>
                <div class="text-[#E5E7EB] text-xl font-light leading-8 3xl:text-[1.25vw] 3xl:leading-[1.667vw]"><span class="font-bold">Ignite</span> Learning</div>
                <div class="text-[#E5E7EB] text-sm font-light leading-4 3xl:text-[0.729vw] 3xl:leading-[0.833vw]">We are committed to fostering learning environments that promote student engagement, belonging and academic growth.</div>
              </div>
              <div class="bg-[#111928]/60 backdrop-blur-[2px] px-3.5 3xl:px-[0.833vw] py-2.5 3xl:py-[0.625vw] rounded-bl-lg rounded-br-lg">
                <div class="flex flex-wrap items-center gap-2.5 3xl:gap-[0.521vw]">
                  {
                    IgniteLearning?.map(item =>
                      <div class="bg-[#313233] border border-[#374151] rounded-sm w-[250px] 3xl:w-[15.625vw] cursor-pointer" onClick={() => { HandleSelectTile("Ignite Learning", item); setVisible(true) }}>
                        <div class="bg-[#1F2A37] px-2.5 3xl:px-[0.521vw] flex items-center justify-between py-2.5 3xl:py-[0.521vw]">
                          <div class="text-[#E5E7EB] text-sm font-normal leading-6 3xl:text-[0.729vw]">{item?.METRIC_NAME}</div>
                          <div class={`rounded-full h-[20px] xl:h-[10px] 3xl:h-[1.042vw] xl:w-[10px] w-[20px] 3xl:w-[1.042vw] ${(item?.METRIC_INDICATOR_TYPE == 'positive' === item?.TARGET_PERCENTAGE < item?.CURRENT_PERIOD) ? 'bg-[#5bd96c]' : 'bg-[#d9685b]'}`}></div>
                        </div>
                        <div class="p-2.5 3xl:p-[0.521vw] bg-[#111928] grid grid-cols-2">
                          <div>
                            <div class="text-[#E5E7EB] text-xs font-normal leading-4 3xl:text-[0.625vw] 3xl:leading-[0.833vw]">Current</div>
                            <div class="text-[#E5E7EB] text-xl font-bold leading-7 3xl:text-[1.25vw] 3xl:leading-[1.458vw]">{item?.METRIC_TYPE == "number" ? `${item?.CURRENT_PERIOD}` : `${item?.CURRENT_PERIOD?.toFixed(1)}%`}</div>
                          </div>
                          <div>
                            <div class="text-[#E5E7EB] text-xs font-normal leading-4 3xl:text-[0.625vw] 3xl:leading-[0.833vw]">LY Var</div>
                            <div class="text-[#E5E7EB] text-lg font-bold leading-7 3xl:text-[0.938vw] 3xl:leading-[1.458vw] flex items-center gap-1"><span>{(item?.LAST_PERIOD_VARIANCE <= 100) ? `${item?.LAST_PERIOD_VARIANCE?.toFixed(1)}%` : "-"}</span>{item?.LAST_PERIOD_VARIANCE > 0 ? <div class="w-0 h-0 border-l-8 border-l-transparent border-b-8 border-b-green-400 border-r-8 border-r-transparent"></div> : <div class="w-0 h-0 border-l-8 border-l-transparent border-t-8 border-t-red-400 border-r-8 border-r-transparent"></div>}</div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div class="bg-[rgba(255,255,255,0.04)] border border-[#2B3B4F] rounded-lg">
              <div class="bg-[#1F2A37] flex items-center gap-6 3xl:gap-[1.302vw] py-2 3xl:py-[0.417vw] px-3.5 3xl:px-[0.781vw] rounded-tl-lg rounded-tr-lg">
                <div><img alt="manu" width="42" height="42" src={Cultivatelogo} /></div>
                <div class="text-[#E5E7EB] text-xl font-light leading-8 3xl:text-[1.25vw] 3xl:leading-[1.667vw]">Cultivate <span class="font-bold">Trust</span></div>
                <div class="text-[#E5E7EB] text-sm font-light leading-4 3xl:text-[0.729vw] 3xl:leading-[0.833vw]">We are committed to fostering learning environments that promote student engagement, belonging and academic growth.</div>
              </div>
              <div class="bg-[#111928]/60 backdrop-blur-[2px] px-3.5 3xl:px-[0.833vw] py-2.5 3xl:py-[0.625vw] rounded-bl-lg rounded-br-lg">
                <div class="flex flex-wrap items-center gap-2.5 3xl:gap-[0.521vw]">

                  {
                    CultivateTrust?.map(item =>
                      <div class={`${item?.METRIC_ID ? "" : 'pointer-events-none'} bg-[#313233] border border-[#374151] rounded-sm w-[250px] 3xl:w-[15.625vw] cursor-pointer`} onClick={() => { HandleSelectTile("Cultivate Trust", item); setVisible(true) }}>
                        <div class="bg-[#1F2A37] px-2.5 3xl:px-[0.521vw] flex items-center justify-between py-2.5 3xl:py-[0.521vw]">
                          <div class="text-[#E5E7EB] text-sm font-normal leading-6 3xl:text-[0.729vw]">{item?.METRIC_NAME}</div>
                          <div class={`rounded-full h-[20px] xl:h-[10px] 3xl:h-[1.042vw] xl:w-[10px] w-[20px] 3xl:w-[1.042vw] ${(item?.METRIC_INDICATOR_TYPE == 'positive' === item?.TARGET_PERCENTAGE < item?.CURRENT_PERIOD) ? 'bg-[#5bd96c]' : 'bg-[#d9685b]'}`}></div>
                        </div>
                        <div class="p-2.5 3xl:p-[0.521vw] bg-[#111928] grid grid-cols-2">
                          <div>
                            <div class="text-[#E5E7EB] text-xs font-normal leading-4 3xl:text-[0.625vw] 3xl:leading-[0.833vw]">Current</div>
                            <div class="text-[#E5E7EB] text-xl font-bold leading-7 3xl:text-[1.25vw] 3xl:leading-[1.458vw]">{item?.METRIC_TYPE == "number" ? `${item?.CURRENT_PERIOD}` : `${item?.CURRENT_PERIOD?.toFixed(1)}%`}</div>
                          </div>
                          <div>
                            <div class="text-[#E5E7EB] text-xs font-normal leading-4 3xl:text-[0.625vw] 3xl:leading-[0.833vw]">LY Var</div>
                            {/* Mock Condition => item?.LAST_PERIOD_VARIANCE<=100 ?remove later */}
                            <div class="text-[#E5E7EB] text-lg font-bold leading-7 3xl:text-[0.938vw] 3xl:leading-[1.458vw] flex items-center gap-1"><span>{`${item?.LAST_PERIOD_VARIANCE == "-" ? "-" : `${item?.LAST_PERIOD_VARIANCE <= 100 ? item?.LAST_PERIOD_VARIANCE?.toFixed(1) : '-'}%`}`}</span>{item?.LAST_PERIOD_VARIANCE > 0 ? <div class="w-0 h-0 border-l-8 border-l-transparent border-b-8 border-b-green-400 border-r-8 border-r-transparent"></div> : <div class="w-0 h-0 border-l-8 border-l-transparent border-t-8 border-t-red-400 border-r-8 border-r-transparent"></div>}</div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>

            <div class="bg-[rgba(255,255,255,0.04)] border border-[#2B3B4F] rounded-lg">
              <div class="bg-[#1F2A37] flex items-center gap-6 3xl:gap-[1.302vw] py-2 3xl:py-[0.417vw] px-3.5 3xl:px-[0.781vw] rounded-tl-lg rounded-tr-lg">
                <div><img alt="manu" width="42" height="42" src={Expandlogo} /></div>
                <div class="text-[#E5E7EB] text-xl font-light leading-8 3xl:text-[1.25vw] 3xl:leading-[1.667vw]">Expanded <span class="font-bold">Opportunity</span></div>
                <div class="text-[#E5E7EB] text-sm font-light leading-4 3xl:text-[0.729vw] 3xl:leading-[0.833vw]">We are committed to fostering learning environments that promote student engagement, belonging and academic growth.</div>
              </div>
              <div class="bg-[#111928]/60 backdrop-blur-[2px] px-3.5 3xl:px-[0.833vw] py-2.5 3xl:py-[0.625vw] rounded-bl-lg rounded-br-lg">
                <div class="flex flex-wrap items-center gap-2.5 3xl:gap-[0.521vw]">

                  {
                    [...ExpandedOportunity, ...ExpandedopertunityDummy]?.map(item =>
                      <div class={` ${item?.METRIC_ID ? "" : 'pointer-events-none'} bg-[#313233] border border-[#374151] rounded-sm w-[250px] 3xl:w-[15.625vw] cursor-pointer`} onClick={() => { HandleSelectTile("Expanded Oportunity", item); setVisible(true) }}>
                        <div class="bg-[#1F2A37] px-2.5 3xl:px-[0.521vw] flex items-center justify-between py-2.5 3xl:py-[0.521vw]">
                          <div class="text-[#E5E7EB] text-sm font-normal leading-6 3xl:text-[0.729vw]">{item?.METRIC_NAME}</div>
                          <div class="bg-[#84E1BC] rounded-full h-[20px] xl:h-[10px] 3xl:h-[1.042vw] xl:w-[10px] w-[20px] 3xl:w-[1.042vw]"></div>
                        </div>
                        <div class="p-2.5 3xl:p-[0.521vw] bg-[#111928] grid grid-cols-2">
                          <div>
                            <div class="text-[#E5E7EB] text-xs font-normal leading-4 3xl:text-[0.625vw] 3xl:leading-[0.833vw]">Current</div>
                            <div class="text-[#E5E7EB] text-xl font-bold leading-7 3xl:text-[1.25vw] 3xl:leading-[1.458vw]">{item?.METRIC_TYPE == "number" ? `${item?.CURRENT_PERIOD}` : `${item?.CURRENT_PERIOD?.toFixed(1)}%`}</div>
                          </div>
                          <div>
                            <div class="text-[#E5E7EB] text-xs font-normal leading-4 3xl:text-[0.625vw] 3xl:leading-[0.833vw]">LY Var</div>
                            <div class="text-[#E5E7EB] text-lg font-bold leading-7 3xl:text-[0.938vw] 3xl:leading-[1.458vw] flex items-center gap-1"><span>{item?.LAST_PERIOD_VARIANCE == "-" ? "-" : `${item?.LAST_PERIOD_VARIANCE <= 100 ? item?.LAST_PERIOD_VARIANCE?.toFixed(1) : '-'}%`}</span><div class="w-0 h-0 border-l-8 border-l-transparent border-b-8 border-b-green-400 border-r-8 border-r-transparent"></div></div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer flex justify-between p-2 mt-[-90px]">
        <div class="flex mt-2">
          {/* <div class="mx-5 mt-2">Conditions of Use</div>
            <div class="mx-5 mt-2">Privacy Notice</div>
            <div class="mx-5 mt-2">@2024 Greece | All right reserved</div> */}
        </div>
        <div class="mr-3">
          <img src={Complogo} height="50px" width="130px" alt="logo" className="mr-[2vw]" />
        </div>
      </div>
    </>
  )

}


export default GoalsAndStrategy