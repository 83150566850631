

import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';
import StudentCharacteristics_bannerImg from "../../assets/images/StudentCharacteristics_banner.jpeg"
import { useDispatch, useSelector } from "react-redux";
import {
  fetchStudent_Characteristics, fetchStudent_Group
} from '../../redux/slices/counter';
import LoaderContainer from "../../components/loaderContainer";


export default function StudentCharacteristics() {
  const DarkMode = useSelector((state) => state.global.DarkMode);
  const dispatch = useDispatch();
  const Student_Characteristicsdata = useSelector(state => state.netsales.Student_Characteristics);
  const Student_Characteristicsdataloading = useSelector(state => state.netsales.Student_Characteristicsloading);
  let Student_Groupdata = useSelector(state => state.netsales.Student_Group);
  let Student_Groupdataloading = useSelector(state => state.netsales.Student_Grouploading);

  console.log('Student_Groupdata', Student_Groupdata)

  useEffect(() => {
    dispatch(fetchStudent_Characteristics({
      "elasticQueryName": "Student_Characteristics",
      "filters": [{
        "columnName": "SCHOOL_YEAR",
        "columnValue": ["2025"],
        "excludeKeyword": false
      }],
      "dynamicColumns": [],
    }));
    dispatch(fetchStudent_Group({
      "elasticQueryName": "Student_Group",
      "filters": [{
        "columnName": "SCHOOL_YEAR",
        "columnValue": ["2025"],
        "excludeKeyword": false
      }],
      "dynamicColumns": [],
    }));
  }, []);

  const [screenWidth, setScreenWidth] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined') {
        const screenWidth = window.innerWidth;
        setScreenWidth(screenWidth);
        console.log(screenWidth, "setScreenWidth");
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const StudentCharacteristicsChartData = Student_Characteristicsdata.reduce((acc, item) => {
    acc['label'].push(item?.LABEL); // Use METRIC_NAME for labels
    acc['value'].push(item?.VALUE); // Push the VALUE into the array
    return acc;
  }, { label: [], value: [] });

  // Combine the labels and values into an array of objects
  const combinedData = StudentCharacteristicsChartData.label.map((label, index) => ({
    label,
    value: StudentCharacteristicsChartData.value[index]
  }));

  // Sort the combined data based on values in ascending order
  const sortedData = combinedData.sort((a, b) => a.value - b.value);

  // Separate the sorted data back into `label` and `value`
  const sortedStudentCharacteristicsChartData = {
    label: sortedData.map(item => item.label),
    value: sortedData.map(item => item.value)
  };



  const StudentCharacteristics = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: DarkMode ? "black" : "white",
      borderColor: "#333",
      textStyle: {
        fontSize: 12,
        fontWeight: "normal",
        color: DarkMode ? "white" : "black",
      },
      axisPointer: {
        type: 'shadow'
      },
      confine: true
    },
    grid: {
      left: screenWidth <= 1450 && screenWidth >= 865 ? '3%' : '6%',
      bottom: '0%',
      top: "10%",
      right: '10%',
      height: '80%',
      containLabel: true
    },

    yAxis: [
      {
        type: 'category',
        position: "left",
        inverse: true,
        data: sortedStudentCharacteristicsChartData.label,
        axisLine: {
          show: false,
          lineStyle: {
            color: '#E3E3E3'
          }
        },
        axisLabel: {
          color: DarkMode ? "white" : "black",
          margin: 10 || 0,
          overflow: "truncate",
          // color: '#fff',
          fontSize: 12,
          align: 'left',
          width: 90,
          fontFamily: 'Roboto, sans-serif',
          padding: [0, 0, 0, -80],
          fontWeight: "normal",
          position: 'insideLeft',
        },
        axisTick: {
          show: false,
        },
      },
    ],

    xAxis: {
      show: false,
      inverse: false,
      min: 0,
      max: 15000,
      interval: 5000,
    },

    series: [
      {
        label: {
          normal: {
            show: true,
            position: "right",
            fontSize: 10,
            // padding: 10,
            "formatter": function (params) {
              // debugger
              // return params.value.VALUE
              return params.value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            "textStyle": {
              "color": DarkMode ? "white" : "black"
            }
          }
        },
        emphasis: {
          focus: 'series'
        },
        data: sortedStudentCharacteristicsChartData.value,
        type: 'bar',
        barWidth: "70%",
        color: '#1F46AB',
        itemStyle: {
          normal: {
            barBorderRadius: [3, 3, 3, 3],
            color: '#1F46AB',
          }
        }

      }
    ]
  };

  const data1 = [...Student_Groupdata]

  const studentGroupData = data1.sort((a, b) => {
    if (a?.["VALUE"] < b?.["VALUE"]) {
      return -1;
    }
    if (a?.["VALUE"] > b?.["VALUE"]) {
      return 1;
    }
    return 0;
  }
  );


  const StudentGroup = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: DarkMode ? "black" : "white",
      borderColor: "#333",
      textStyle: {
        fontSize: 12,
        fontWeight: "normal",
        color: DarkMode ? "white" : "black",
      },
      axisPointer: {
        type: 'shadow'
      },
      confine: true
    },
    grid: {
      left: screenWidth <= 1450 && screenWidth >= 865 ? '3%' : '6%',
      bottom: '20%',
      top: "10%",
      right: '10%',
      height: '70%',
      containLabel: true
    },

    yAxis: [
      {
        type: 'category',
        position: "left",
        inverse: true,
        data: studentGroupData.map(itr => itr.LABEL),
        axisLine: {
          show: false,
          lineStyle: {
            color: '#E3E3E3'
          }
        },
        axisLabel: {
          color: DarkMode ? "white" : "black",
          margin: 10 || 0,
          overflow: "truncate",
          // color: '#fff',
          fontSize: 12,
          align: 'left',
          width: 90,
          fontFamily: 'Roboto, sans-serif',
          padding: [0, 0, 0, -80],
          fontWeight: "normal",
          position: 'insideLeft',
        },
        axisTick: {
          show: false,
        },
      },
    ],

    xAxis: {
      show: false,
      inverse: false,
      min: 0,
      max: 15000,
      interval: 5000,
    },

    series: [
      {
        label: {
          normal: {
            show: true,
            position: "right",
            fontSize: 10,
            // padding: 10,
            "formatter": function (params) {
              // debugger
              // return params.value.VALUE
              return params.value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            "textStyle": {
              "color": DarkMode ? "white" : "black"
            }
          }
        },
        emphasis: {
          focus: 'series'
        },
        data: studentGroupData.map(itr => itr.VALUE),
        type: 'bar',
        barWidth: "70%",
        color: '#1F46AB',
        itemStyle: {
          normal: {
            barBorderRadius: [3, 3, 3, 3],
            color: '#1F46AB',
          }
        }

      }
    ]
  }

  // const StudentGroup = {
  //   title: {
  //     // text: 'Student Group',
  //     left: 10,
  //     top: 0,
  //     textStyle: {
  //       fontSize: 12,
  //       fontWeight: "normal",
  //       color: '#fff',
  //     },
  //   },

  //   XAxis : {
  //     show :false
  //   },
  //   yAxis : {
  //     type :'category'
  //   },
  //   // tooltip: {
  //   //   trigger: 'item',
  //   //   backgroundColor: 'rgba(50,50,50,0.7)',
  //   //   borderColor: "#333",
  //   //   textStyle: {
  //   //     fontSize: 12,
  //   //     fontWeight: "normal",
  //   //     color: '#fff',
  //   //   },
  //   //   axisPointer: {
  //   //     type: 'shadow'
  //   //   }
  //   // },
  //   tooltip: {
  //     trigger: "item",
  //     formatter: function (params) {
  //       return (
  //         params.name + " : " + params.percent.toFixed(2) + "%"
  //       );
  //     },
  //     confine: true
  //   },

  //   // grid: {
  //   //   left: -50,
  //   //   bottom: '100%',
  //   //   top: "100%",
  //   //   containLabel: true
  //   // },
  //   // legend: {
  //   //   itemGap: 4,
  //   //   orient: 'vertical',
  //   //   bottom: "60%",
  //   //   // left: "0%",
  //   //   itemWidth: 6,
  //   //   itemHeight: 6,
  //   //   textStyle: {
  //   //     color: "#fff",
  //   //     fontSize: '9',
  //   //     fontStyle: 'normal',
  //   //   }
  //   // },

  //   legend: {
  //     orient: 'vertical',
  //     center: 'center',
  //     // textStyle: {
  //     //   padding: [4, 20, 4, 20],
  //     //   borderRadius: 4
  //     // },
  //     // right: '10%',
  //     left: "51%",
  //     itemWidth: 8.5,
  //     itemHeight: 8,
  //     height: "75%",
  //     bottom: 140,
  //     textStyle: {
  //       color: DarkMode ? "white" : "black",
  //       fontSize: '11',
  //       fontStyle: 'normal',
  //       fontFamily: 'Roboto, sans-serif',
  //     },
  //     formatter : (label)=>{
  //       if(label === "Black/African-American"){
  //         return label.length > 10 ? `${label?.substring(0,10)}...` :label
  //       }
  //           return label.length >  (screenWidth <= 1450 && screenWidth >= 1100  ? 10 : 20) ? `${label?.substring(0,(screenWidth <= 1450 && screenWidth >= 1100  ? 10 : 20))}...` :label
  //     }
  //   },
  //   series: [
  //     {
  //       name : Student_Groupdata?.map((item)=> item.LABEL),
  //       data : Student_Groupdata?.map((item)=> item.VALUE)
  //       // name: 'Access From',
  //       // type: 'pie',
  //       // radius: '40%',
  //       // center: ['28%', '28%'],
  //       // avoidLabelOverlap: true,
  //       // label: {
  //       //   show: true,
  //       //   position: 'left',
  //       //   formatter: '{d}%',
  //       //   position: 'inside',
  //       //   textStyle: {
  //       //     fontSize: '8',
  //       //     color: "#fff"
  //       //   }
  //       // },
  //       // // emphasis: {
  //       // //   label: {
  //       // //     show: true,
  //       // //     fontSize: 10,
  //       // //     fontWeight: 'bold'
  //       // //   }
  //       // // },
  //       // labelLine: {
  //       //   show: false
  //       // },
  //       // color: [
  //       //   "#2C7AB8",
  //       //   "#6F2E67",
  //       //   "#2C4990",
  //       //   "#32A5AC",
  //       //   "#393469",
  //       //   "#359760",
  //       //   "#992837",
  //       // ],

  //       // data: [
  //       //   {
  //       //     value: Student_Groupdata[0]?.VALUE,
  //       //     name: Student_Groupdata[0]?.LABEL,
  //       //     itemStyle:
  //       //     {
  //       //       color: '#393469'
  //       //     }
  //       //   },
  //       //   {
  //       //     value: Student_Groupdata[1]?.VALUE,
  //       //     name: Student_Groupdata[1]?.LABEL,
  //       //     itemStyle:
  //       //     {
  //       //       color: '#32A5AC'
  //       //     }
  //       //   },
  //       //   {
  //       //     value: Student_Groupdata[2]?.VALUE,
  //       //     name: Student_Groupdata[2]?.LABEL,
  //       //     itemStyle:
  //       //     {
  //       //       color: '#992837'
  //       //     }
  //       //   },
  //       //   {
  //       //     value: Student_Groupdata[3]?.VALUE,
  //       //     name: Student_Groupdata[3]?.LABEL,
  //       //     itemStyle:
  //       //     {
  //       //       color: '#359760'
  //       //     }
  //       //   },
  //       //   {
  //       //     value: Student_Groupdata[4]?.VALUE,
  //       //     name: Student_Groupdata[4]?.LABEL,
  //       //     itemStyle:
  //       //     {
  //       //       color: '#2C4990'
  //       //     }
  //       //   },
  //       //   {
  //       //     value: Student_Groupdata[5]?.VALUE,
  //       //     name: Student_Groupdata[5]?.LABEL,
  //       //     itemStyle:
  //       //     {
  //       //       color: '#402B50'
  //       //     }
  //       //   },
  //       //   {
  //       //     value: Student_Groupdata[6]?.VALUE,
  //       //     name: Student_Groupdata[6]?.LABEL,
  //       //     itemStyle:
  //       //     {
  //       //       color: '#2C7AB8'
  //       //     }
  //       //   }
  //       // ]
  //     }
  //   ]
  // };


  return (
    <div className="EnrollmentTile text-[#fff]">
      <div className="relative h-[130px]">
        <div className=" h-[130px] ">
          <img src={StudentCharacteristics_bannerImg} className="h-[130px] w-full " />
        </div>
        <div className="w-full absolute bottom-0 h-[27px] bg-[#1d192f9c] py-1 px-3">
          <h3 className="text-[15px] font-[400] text-[#fff]">Student Characteristics</h3>
        </div>
      </div>
      <div className=" pb-2 pt-0">
        <LoaderContainer loading={Student_Characteristicsdataloading}>
          <ReactEcharts style={{ width: screenWidth <= 1450 && screenWidth >= 865 ? 320 : 360, height: 150 }} option={StudentCharacteristics} />
        </LoaderContainer>
      </div>

      <div className="border-t py-1 border-[#ffffff26] mt-[-30px]">

        <div className="StudetGroup">

          <div className="EnrollmentTitle text-white text-[15px] mb-[5px] text-left mx-3 font-[400] my-2">Ethnicity</div>
          <LoaderContainer loading={Student_Groupdataloading}>
            <ReactEcharts style={{ width: screenWidth <= 1450 && screenWidth >= 865 ? 320 : 360, height: 150 }} option={StudentGroup} />
          </LoaderContainer>
        </div>

      </div>
    </div>
  )
}