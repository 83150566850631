import axios from 'axios';

const client = axios.create({

  // dev
  // baseURL: 'https://localhost:8094/'
  // prod
  // baseURL: 'https://localhost:8095/'
  //UAT_NEW_URL
  // baseURL: 'https://greecedashboardapi-wckqd7o3eq-uw.a.run.app'
  // uat
  // baseURL: 'https://uat.gcsd.executivedashboard.innive.com/'
  // prod
  baseURL: 'https://gcsd.executivedashboard.innive.com/'

});

export default client;